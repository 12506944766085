@import "../../../app/styles/variables/variables";
@import "../../../app/styles/mixins/media-queries.scss";

.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  z-index: 650;
  background-color: $dark-blue;
  box-shadow: 0 0.2rem 4rem 0 rgba(40, 58, 151, 0.4);
  border-radius: 0 0 0.8rem 0.8rem;
  &__content {
    display: flex;
    position: relative;
    width: 120.8rem;
  }
  &__menu {
    display: flex;
    align-items: center;
  }
  &__logo {
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
    margin-left: 1.2rem;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.8;
    }
  }
  &__navigator {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &__buttons {
    position: relative;
    margin-left: auto;
    display: flex;
    align-items: center;
    &_avatar {
      display: flex;
      align-items: center;
      padding: 0 0.4rem;
      margin-left: 4px;
      height: 100%;
      cursor: pointer;
      transition: background-color 0.2s ease;
      &_is_dropdown {
        background-color: rgba(255, 255, 255, 0.2);
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    &_chevron {
      transition: all 0.2s ease;
      &_is_opened {
        transform: rotate(180deg);
      }
    }
  }
  &__dropdown {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(0, 99%);
    background-color: $dark-blue;
    border-radius: 0 0 6px 6px;
    transition: opacity 0.2s ease-in-out;
    cursor: auto;
    animation: fade-out-modal 0.2s ease-in-out;
    @include tablet-max {
      width: 100%;
    }
    &_is_closed {
      animation: fade-in-modal 0.2s ease-in-out;
    }
    &_ratings {
      display: flex;
      margin-top: 1rem;
      &_element:not(:first-child) {
        margin-left: 1rem;
      }
    }
    &_profile {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 2rem 1.2rem;
      transition: background 0.2s ease;
      text-decoration: none;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      @include tablet-max {
        padding: 1.6rem 2.4rem;
      }
    }
    &_info {
      margin-left: 1.2rem;
      @include tablet-max {
        width: 100%;
      }
    }
    &_name {
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $white;
    }
    &_chevron {
      margin-left: 1.2rem;
      transform: rotate(-90deg);
    }

    &_switcher {
      display: flex;
      background-color: $extra-light-gray;
      border-radius: 0.8rem;
      width: 100%;
      padding: 0.4rem;

      &_switch {
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 600;
        width: 100%;
        transition: all 0.3s ease-in;
        padding: 0.3rem 0;
        line-height: normal;
        color: $black;
        border-radius: 0.6rem;

        &__active {
          box-shadow: 0 0 20px 0 rgba(40, 58, 151, 0.2);
          background-color: $white;
        }

        &:last-child {
          margin-left: 0.4rem;
        }
      }
    }
    &_button_bottom {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 1.6rem 1.2rem;
      border-top: 1px solid rgba(212, 218, 236, 0.2);
      transition: background 0.2s ease;
      //cursor: pointer; todo: later
      &:hover:has(> span) {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.2);
      }
      @include tablet-max {
        padding: 1.6rem 2.4rem;
      }
    }
  }
}

.links_menu {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: $white;
  transform: translate(0, 93%);
  animation: fade-out-modal 0.2s ease-in-out;
  box-shadow: 0 0 20px 0 rgba(40, 58, 151, 0.2);
  border-radius: 1.6rem;
  &__is_closed {
    animation: fade-in-modal 0.2s ease-in-out;
  }
  &__list {
    padding: 2rem 0;
    list-style: none;

    @include tablet-max {
      padding: 0 0 2.4rem 0;
    }
  }
  &__element {
    transition: background-color 0.2s ease;
    min-width: 26.9rem;
    &:hover {
      background-color: #f4f6ff;
    }
    @include tablet-max {
      &:active {
        background-color: #f4f6ff;
      }
    }
  }
  &__link {
    display: flex;
    align-items: center;
    padding: 0.8rem 2rem;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    color: $black;
    text-decoration: none;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.8rem;
      border-radius: 0.4rem;
      padding: 0.4rem;
      background-color: $dark-blue;
    }

    @include tablet-max {
      font-size: 1.6rem;
      font-weight: 600;
      padding: 0.8rem 2.4rem;
    }
  }
}

@keyframes fade-out-modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in-modal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.adminAvatar {
  border: 2px solid yellow;
}
.hrAvatar {
  border: 2px solid green;
}
