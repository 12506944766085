@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils";

.button {
  border: 0.1rem solid transparent;
  color: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 1.2rem 2.4rem;
  text-decoration: none;
  transition: color 100ms linear, background-color 100ms linear;
  appearance: none;
  cursor: pointer;
  outline: none;
  border-radius: $border-radius-small;
  position: relative;
  user-select: none;
  font-size: 1.4rem;

  &:focus {
    box-shadow: 0 0 0 0.4rem lighten($button-primary-bg, 50%);
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 0.4rem lighten($button-primary-bg, 50%);
  }

  &--default {
    font-size: 1.6rem;
    background-color: inherit;
    padding: 0;

    &:focus {
      color: lighten($dark-blue, 5%);
    }

    &:active {
      color: darken($dark-blue, 5%);
    }
  }

  &--block {
    display: flex;
    flex-grow: 1;
    width: 100%;
  }

  &--small {
    height: 4.2rem;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 0 2rem;

    @include tablet-max {
      height: 3.6rem;
      font-size: 1.2rem;
      padding: 0 1rem;
    }
  }

  &--x-small {
    height: 3.2rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0 2rem;
  }

  &--x-small-rounded {
    border-radius: 1.6rem;
  }

  &--large {
    height: 4.8rem;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 0 2.4rem;
  }

  &--primary {
    background: $dark-blue;
    color: $white;

    &:hover {
      background-color: #3d4da1;
      border-color: #3d4da1;
    }

    &:active {
      background-color: #243488;
      border-color: #243488;
    }
  }

  &--transparent {
    background: none;
    border-color: transparent;
    color: $dark-blue;
  }

  &--transparent-white {
    background: none;
    border-color: transparent;
    color: $white;

    &:hover {
      color: rgba($white, 0.8);
    }

    &:focus {
      box-shadow: 0 0 0 0.4rem rgba($white, 0.25);
      color: $white;
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 0.4rem rgba($white, 0.25);
    }
  }

  &--outline {
    border-color: $dark-blue;
    color: $dark-blue;
    background-color: $neutral-n-0;

    &:hover {
      background-color: $neutral-n-40;
    }

    &:active {
      background-color: $neutral-n-50;
    }
  }

  &--secondary {
    background-color: $red-r-10;
    border-color: $red;
    color: $red;

    &:hover {
      background-color: $red-r-20;
    }

    &:active {
      background-color: $red-r-30;
    }
  }

  &--light {
    background-color: $white;
    border: none;
    padding: 1rem 1.8rem;

    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.9rem;
    color: $black;

    &:hover {
      background-color: darken($white, 15%);
    }
  }

  &--white {
    background-color: $white;
    border: none;
    color: $black;

    &:hover {
      background-color: rgba($white, 0.8);
    }

    &:focus {
      box-shadow: 0 0 0 0.4rem rgba($white, 0.25);
      color: $black;
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 0.4rem rgba($white, 0.25);
    }
  }

  &--light-blue {
    background-color: $neutral-n-10;
    border-color: $neutral-n-10;
    color: $dark-blue;

    &:hover {
      background-color: $neutral-n-40;
    }

    &:active {
      border-color: $neutral-n-40;
    }

    &:focus {
      border-color: $neutral-n-40;
    }

    &:focus:not(:focus-visible) {
      background-color: $lightest-gray;
      border-color: $lightest-gray;

      &:hover {
        border-color: $extra-light-purple;
        background-color: $extra-light-purple;
      }

      &:active {
        border-color: darken($extra-light-purple, 5%);
        background-color: darken($extra-light-purple, 5%);
      }
    }

    &:focus-visible {
      border-color: darken($extra-light-purple, 5%);
      background-color: darken($extra-light-purple, 5%);
    }
  }

  &--light-purple {
    background-color: $extra-light-purple;
    border-color: $extra-light-purple;
    color: $dark-blue;

    &:hover {
      background-color: darken($extra-light-purple, 5%);
      border-color: darken($extra-light-purple, 5%);
    }
  }

  &--gray {
    background-color: $extra-light-gray;
    border: none;
    color: $white;
  }

  &--green {
    background-color: $light-green;
    border-color: $green;
    color: $green;

    &:hover {
      background-color: rgba($green, 0.3);
    }

    &:focus {
      color: $green;
    }
  }

  &--orange {
    background-color: $white;
    border: none;
    color: $orange;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    outline: 0;
  }

  &--no-padding {
    &-x {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--bold {
    font-weight: 600;
  }

  &--loading {
    pointer-events: none;
  }

  &--loading &__content {
    opacity: 0;
  }

  &__loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      animation: 1s linear 0s normal none infinite running rotate;
    }
  }

  &__icon {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 1rem;
    @include backgroundImage();

    &--pen-blue {
      background-image: url("../../../shared/images/icons/pen-blue.svg");
    }

    &--trash-red {
      background-image: url("../../../shared/images/icons/trash-red.svg");
    }

    &--close {
      background-image: url("../../../shared/images/icons/icon-close-blue.svg");
    }

    &--play {
      width: 1.6rem;
      height: 2rem;
      background-image: url("../../../shared/images/icons/icon-play-white.svg");
    }
  }

  &__link {
    &--white {
      color: $white;
    }

    &--blue {
      color: $dark-blue;
    }

    &--red {
      color: $red;
    }
  }

  &__content {
    @include flex($align-items: center, $justify-content: center);

    width: 100%;

    a {
      color: $dark-blue;
      text-decoration: none;
    }

    &--no-wrap {
      white-space: nowrap;
    }
  }

  &--border-blue {
    color: $dark-blue;
    border-color: $dark-blue;

  &:hover {
    background-color: darken($extra-light-purple, 5%);
  }
  }

}
