@import "../../app/styles/mixins/media-queries";

.moderation-page {

  &-data-grid-wrapper {
    height: calc(100vh - 10rem);

    @include mobile-landscape {
      height: initial;
    }

    @include tablet-landscape {
      height: initial;
    }
  }

  &__coordination {

    &-buttons {
      button:first-child {
        margin-right: 1rem;
      }
    }

    &__confirmed {
      font-weight: initial;
      padding: initial;
      color: var(--green)
    }

    &__rejected {
      font-weight: initial;
      padding: initial;
      color: var(--red)
    }
  }

}