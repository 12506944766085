@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/font";

.nav-list {
  &__item {
    white-space: nowrap;
  }

  &--in-mobile-dropdown {
    width: 100%;

    .nav-list__item {
      padding: 1.6rem;
      width: 100%;
      color: $white;
      text-decoration: none;
      white-space: normal;
      text-align: center;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 2.2rem;
      display: block;

      @include tablet-max {
        padding: 1.6rem 2.4rem;
        text-align: start;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }

      &--active {
        box-shadow: inset 0.5rem 0 0 $white;
      }
      &:active {
        background: rgba(255, 255, 255, 0.3);
      }

      &--no-access {
        color: $color-15;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  &--in-header {
    @include flex();
    height: 100%;

    .nav-list__item {
      @include flex($align-items: center, $justify-content: center);

      margin-right: 5rem;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2.5rem;
      height: 100%;
      color: $dark-gray;
      text-decoration: none;
      white-space: nowrap;

      @include tablet-max {
        margin-right: 3rem;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }

      &--active {
        color: $dark-blue;
        box-shadow: inset 0 -0.4rem 0 $dark-blue;

        @include tablet-max {
          box-shadow: inset 0 -0.3rem 0 $dark-blue;
        }
      }

      &--no-access {
        color: $color-15;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  &--in-page {
    @include flex();

    border-radius: 0.5rem;
    overflow: hidden;

    .nav-list__item {
      @include flex($align-items: center, $justify-content: center);
      @include set-font(null, 1.8rem, null, 700);

      min-width: 21rem;
      height: 5.2rem;
      padding: 0 1.5rem;
      color: $dark-blue;
      background-color: $color-16;
      text-decoration: none;

      @include tablet-max {
        padding: 1rem;
        min-width: 10rem;
        height: auto;
        font-size: 1.3rem;
      }

      &--active {
        color: $white;
        background-color: $dark-blue;
      }

      &--no-access {
        color: $color-15;
        cursor: not-allowed;
        pointer-events: none;
      }

      &--separated {
        color: $dark-blue;
        font-weight: 600;
        font-size: $font-size-3;
        margin-right: 1em;
        border-radius: 0.6rem;
        background: $extra-light-purple;

        &.nav-list__item--active {
          background: $white;
          border: 0.1rem solid $extra-light-purple;
        }
      }
    }
  }

  &--in-header-new {
    display: flex;
    align-items: center;
    justify-content: center;

    .nav-list__item {
      padding: 2rem 1.8rem;
      position: relative;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.8rem;
      text-decoration: none;
      color: $white;
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 400%;
        bottom: 0;
        left: 0;
        z-index: -1;
        transition: background-color 0.2s ease;
      }
      &:hover::before {
        background-color: rgba(255, 255, 255, 0.2);
      }
      &--active::before {
        border-bottom: 3px solid var(--neutral-n-0, #fff);
      }

      &--no-access {
        color: $color-15;
        cursor: not-allowed;
        pointer-events: none;
      }

      &--separated {
        color: $dark-blue;
        font-weight: 600;
        font-size: $font-size-3;
        margin-right: 1em;
        border-radius: 0.6rem;
        background: $extra-light-purple;

        &.nav-list__item--active {
          background: $white;
          border: 0.1rem solid $extra-light-purple;
        }
      }
    }
  }
}
