@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/media-queries";

.assessment-parameters-popup {
  width: 63rem;
  min-height: 12rem;

  @include tablet-max {
    width: 100%;
  }

  &__item {
    margin-bottom: 3.5rem;
  }

  &__copy {
    width: 100%;
    display: flex;

    &-info {
      margin-right: 1rem;
      padding: 0 1.2rem;
      max-width: calc(100% - 4.8rem);
      background: var(--lightest-gray);
      align-items: center;
      flex: 1;
      border-radius: var(--border-radius-small);
    }
  }

  &__list {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__formulas {
    margin-top: 1.6rem;

    &-title {
      @include flex();

      margin-bottom: 1.2rem;
      font-weight: normal;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: var(--gray);

      &-item {
        min-width: 13rem;
      }
    }
  }

  &__params {
    display: grid;
    gap: 0 1rem;
    grid-template-columns: 1fr 1fr;

    @include mobile {
      grid-template-columns: 1fr;
    }

    &-item {
      &--string,
      &--number,
      &--integer {
        margin-bottom: 3.5rem;
      }

      &--string {
        order: 1;
      }

      &--number,
      &--integer {
        order: 2;
      }

      &--array-number,
      &--array-integer {
        margin-bottom: 4.1rem;
        order: 3;

        @include tablet {
          grid-column: 1 / span 2;
        }
      }

      &--boolean {
        margin-bottom: 2rem;
        order: 4;
      }
    }
  }
  &__send_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    padding-bottom: 3rem;
  }
  &__button_submit {
    width: 100%;
    transition: opacity 0.2s ease;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &:hover {
      opacity: 0.8;
    }
  }
  &__button_cancel {
    width: 100%;
    margin-left: 2rem;
    border-color: $red;
    transition: opacity 0.2s ease;
    & > span > span {
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $red;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
