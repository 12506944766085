@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils.scss";

.notifications-newsletter-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  overflow: inherit;
  flex: 1;

  &__info-page {
    @include flex($flex-direction: column);

    //noinspection CssOverwrittenProperties
    @include mobile {
      padding-bottom: (5.6rem + 2rem + 0.5rem);
      padding-bottom: calc(
              env(safe-area-inset-bottom) + #{5.6rem + 2rem + 0.5rem}
      );
    }

    &-data {
      @include flex($flex-direction: column);

      align-items: normal;

      @include mobile {
        margin-bottom: 1rem;
      }
    }

    &-form {
      display: grid;
      gap: 2rem;
      grid-template-columns: minmax(min-content, 40.8rem) minmax(25rem, 42rem);
      grid-template-areas:
      "info-page-data info-page-avatar"
      "info-page-data info-page-resume"
      "info-page-data ."
      "info-page-data .";

      @include mobile {
        width: 100%;
        display: flex;
        gap: initial;
        flex-direction: column;
        align-items: stretch;
      }
    }
  }

  &__base-box {
    min-width: 41rem;
    margin-bottom: 3rem;
    min-height: 20rem;
    display: grid;
    grid-area: 5;

    &-title {
      margin-bottom: 1.5rem;
      font-size: 1.3rem;
    }

    &-item {
      margin-bottom: 1.5rem;
    }

    &-avatar {
      display: grid;
      grid-gap: 2rem;
      gap: 2rem;
      align-items: start;
      grid-template-areas:
        "info-page-data info-page-avatar"
        "info-page-data info-page-resume"
        "info-page-data ."
        "info-page-data .";
    }
  }
}
.error-message {
  color: red;
  font-size: 13px;
}
