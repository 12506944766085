@import "src/app/styles/mixins/grid.scss";

.base-date-picker {
  &__title {
    margin-bottom: 1rem;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $gray;
  }

  &__items {
    @include row($x-margin: -0.5rem);
  }

  &__item {
    @include col($x-margin: 0.5rem);

    &:nth-child(1), &:nth-child(3) {
      width: 10.5rem;
    }

    &:nth-child(2) {
      flex-grow: 1;
    }
  }
}
