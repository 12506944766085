@import "src/app/styles/variables/variables";
@import "src/app/styles/mixins/media-queries";

$border-radius: 1rem;

$background-card: $tooltips-bg;
$width-desktop: 63rem;
$padding: 2rem;
$mobile-top-padding: 1.2rem;
$mobile-side-padding: 1.5rem;
$gap: 2rem;

$text-color: $white;
$text-color-primary: $light-blue;

$button-background: $white;
$button-color: $black;

$badge-wait-background: rgba(255, 255, 255, 0.1);
$badge-wait-color: #ff9900;

.base-notification-on-page-card {
  padding: $padding;
  border-radius: $border-radius;
  background: $background-card;
  color: $text-color;
  width: 100%;

  @include mobile() {
    padding: $mobile-top-padding $mobile-side-padding;
  }

  @include tablet {
    width: $width-desktop;
  }
}

.base-notification-on-page-button {
  background: $button-background;
  color: $button-color;
  font-size: $font-size-2;
  font-weight: 700;
  line-height: 1.9rem;
  width: fit-content;
  padding: 1rem 2rem;

  @include mobile() {
    font-size: $font-size-1;
    line-height: 1.6rem;
  }
}
