@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils";

.base-snackbar {
  min-height: 5.7rem;
  padding: 1.6rem 2.4rem;
  position: fixed;
  left: 50%;
  opacity: 0;
  top: 8rem;
  box-sizing: border-box;
  transition: transform 1s, visibility 1s 1s;
  transform: translateX(-50%) translateY(-20rem);
  z-index: var(--z-index-base-snackbar);
  border-radius: 0.8rem;
  cursor: pointer;
  background-color: $tooltips-bg;
  text-align: center;
  display: flex;
  justify-content: center;

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(1rem);
    background-color: $tooltips-bg;
  }

  @include laptop-max {
    left: calc((100% + var(--aside-bar-small-width)) / 2);
  }

  @include tablet-max {
    width: calc(100% - 3rem);
    transform: translateX(0) translateY(-20rem);
    top: 7.5rem;
    left: 1.5rem;
  }

  &--vertical-centered {
    transform: translateX(-50%) translateY(-3rem);
    top: 48svh;
  }

  &--visible {
    transition: transform 0.5s, opacity .5s;
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(0);

    @include tablet-max {
      transform: translateX(0) translateY(0);
    }
  }

  &__text {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.7rem;
    color: $white;
    display: flex;
    align-items: center;

    &--with-icon {
      &::before {
        margin-right: 1.5rem;
        content: "";
        display: block;
        min-width: 2rem;
        width: 2rem;
        height: 2rem;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &_success {
        &::before {
          background-image: url("../../../shared/images/icons/icon-tick.svg");
        }
      }

      &_error {
        &::before {
          background-image: url("../../../shared/images/icons/icon-cross-red.svg");
        }
      }

      &_loading {
        &::before {
          background-image: url("../../../shared/images/icons/loading.svg");
          animation: 1s linear 0s normal none infinite running rotate;
          fill: white;
        }
      }
    }

    @include tablet-max {
      font-size: 1.6rem;
      line-height: 2.2rem;
      text-align: center;
    }
  }
}

.timer {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 2.5rem;
}
