@import "src/app/styles/index";

.wrapper {
  display: flex;
  gap: 6px;
  align-items: center;
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  font-size: 1px;

  &_part {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid $neutral-n-50;
    font-size: 20px;
    font-weight: 700;
    line-height: 130%;
    cursor: text;
    user-select: none;
  }
}

.active::after {
  display: block;
  height: 1.3em;
  width: 1px;
  background: $neutral-n-300;
  content: "";
  position: absolute;
  animation: 1s caret step-end infinite;
}

.active:not(:empty)::after {
  margin-left: 20px;
}

.delimiter {
  font-size: 24px;
  font-weight: 700;
  line-height: 127%;
  user-select: none;
}

@keyframes caret {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
