@import "src/app/styles/mixins/grid.scss";

@import "src/app/styles/mixins/media-queries";

.players-page-data-grid-wrapper {
  height: calc(100vh - 22rem);

  @include mobile-landscape {
    height: initial;
  }

  @include tablet-landscape {
    height: initial;
  }
}
