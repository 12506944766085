@import "src/app/styles/mixins/media-queries.scss";

.invitePopup {

  .base-popup__children {
    align-items: center;
  }

  &_title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1.2rem;
  }

  &_content {
    max-width: 44rem;
    padding: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include tablet-max {
      max-width: initial;
    }
  }

  &_desc {
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  &_switcher {
    margin-bottom: 2rem;
  }

  &_link {
    width: 100%;
    margin-bottom: 4rem;
  }

  &_buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    &__text {
      margin-right: 0.4rem;
    }

    button:first-child {
      margin-right: 2rem;
    }
  }
}