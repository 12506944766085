@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/utils";

.base-info {
  &__button {
    border: 0;
    appearance: none;
    background: none;
    cursor: pointer;
    color: $gray;

    &:hover {
      color: $dark-blue;
    }
  }
}
