@import "src/app/styles/variables/variables";
@import "src/app/styles/mixins/media-queries";
@import "../variables.scss";

.base-tile {
  @extend .base-notification-on-page-card;

  position: relative;

  @include mobile() {
    position: sticky;
    top: 0;
  }

  &__title {
    font-weight: 700;
    font-size: $font-size-4;
    line-height: 2.5rem;
    padding-bottom: 1.6rem;

    @include mobile() {
      font-size: $font-size-2;
      line-height: 1.9rem;
    }
  }

  &__card {
    display: flex;
    align-items: stretch;
    gap: $gap;
  }

  &__image {
    $image-size: 8rem;

    flex-shrink: 0;
    width: $image-size;
    height: $image-size;
    border-radius: 0.6rem;

    @include mobile() {
      $image-size: 5.6rem;

      width: $image-size;
      height: $image-size;
    }
  }

  &__content {
    width: 100%;
  }

  &__icon-cross {
    position: absolute;
    top: $gap;
    right: $gap;

    @include mobile() {
      display: none;
    }
  }

  &__button {
    width: 100%;
    margin-top: 1.5rem;
    @extend .base-notification-on-page-button;

    @include tablet() {
      display: none;
    }
  }
}

.tile-badge {
  background-color: $badge-wait-background;
  font-size: $font-size-2;
  line-height: 1.9rem;
  padding: 0.4rem 1.2rem;
  border-radius: 0.4rem;
  width: fit-content;
  margin-left: auto;

  @include mobile() {
    position: absolute;
    top: $mobile-top-padding;
    right: $mobile-side-padding;
    font-size: 1rem;
    line-height: 1.4rem;
    padding: 0.4rem 0.8rem;
  }

  &-wait {
    color: $badge-wait-color;
  }
}
