.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 20.8rem;
  gap: 1.2rem;
}

.title {
  font-size: 1.4rem;
  font-weight: 700;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__name {
    color: var(--extra-light-gray)
  }
}