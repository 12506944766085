@import "src/app/styles/mixins/grid";

@import "src/app/styles/mixins/media-queries";

@import "src/app/styles/variables/variables";

.notifications-panel {
  @include flex(
    $align-items: flex-start,
    $justify-content: flex-start,
    $flex-direction: column
  );

  //margin-left: $aside-bar-width;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0;
  height: 90vh;
  background: rgba($dark-blue, 0.2);
  opacity: 1;
  z-index: var(--z-index-notifications-panel);

  @include tablet-max {
    margin-left: 0;
    width: 100vw;
    height: calc(100vh - 6rem);
    margin-top: 6rem;
  }

  &__wrapper {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 4rem 0 0 0;
    width: 69rem;
    background: $white;
    left: 0;
    top: 0;
    box-shadow: 0 0 20px 0 rgba(40, 58, 151, 0.2);
    transform: translate(-95%, 6rem);
    border-radius: 2rem;
    transition: 0.5s;
    animation: popup-bg-show 0.5s;
    overflow: auto;

    @keyframes popup-bg-show {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @include tablet-max {
      width: 100%;
      height: 100%;
      border-radius: 0;
      transform: none;
      padding: 1.2rem 0 0 0;
      box-shadow: none;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    min-height: 8.6rem;

    @include tablet-max {
      padding: 0 2rem 0 1rem;
      min-height: 6rem;
      border-bottom: 0.1rem solid $color-6;
    }

    @include mobile {
      flex-wrap: wrap;
      position: sticky;
      top: 0;
      background: $white;
      box-shadow: inset 0px -0.5px 0px #e0e4f8;
      z-index: 10;
    }
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 0;
    font-size: $font-size-7;
    font-weight: 800;
    line-height: 2.7rem;

    @include tablet-max {
      font-size: $font-size-3;
      margin-right: 1rem;
    }
  }

  &__button-close {
    display: none;
    color: $dark-blue;

    @include tablet-max {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      margin-right: 0.5rem;
      outline: none;
      border-radius: 0.6rem;
      cursor: pointer;
      user-select: none;
    }

    &:hover {
      color: lighten($dark-blue, 15%);
    }

    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 0.4rem #ccd2f2;
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:active {
      color: darken($dark-blue, 5%);
    }
  }

  &__viewed {
    display: flex;
    align-items: center;
    min-height: 3.2rem;
    padding: 0.2rem;
    font-size: $font-size-2;
    font-weight: 700;
    color: $dark-blue;
    transition: color 100ms linear, background-color 100ms linear;
    cursor: pointer;
    outline: none;
    border-radius: 0.6rem;
    user-select: none;

    &:hover {
      color: lighten($dark-blue, 15%);
    }

    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 0.4rem #ccd2f2;
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:active {
      color: darken($dark-blue, 5%);
    }
  }

  &__icon {
    margin-right: 0.8rem;
    color: inherit;

    @include tablet-max {
      color: inherit;
    }

    &--arrow {
      display: none;

      @include tablet-max {
        display: block;
        margin: 0;
      }
    }

    &--arrow-down {
      display: none;

      @include tablet-max {
        display: block;
        margin-right: 0.8rem;
        transform: rotate(-90deg);
      }
    }
  }

  &__divide {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    min-height: 3.4rem;
    font-size: $font-size-3;
    color: $extra-light-gray;

    @include tablet-max {
      font-size: $font-size-2;
    }

    &::before {
      content: "";
      position: relative;
      margin-right: 1rem;
      width: 4rem;
      height: 0.1rem;
      background-color: $extra-light-gray;
    }

    &::after {
      content: "";
      position: relative;
      margin-left: 1rem;
      width: 100%;
      height: 0.1rem;
      background-color: $extra-light-gray;
    }

    &--new {
      //color: $red;

      &::before,
      &::after {
        //background-color: $red;
      }
    }
  }

  &__show-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 6.2rem;
    margin-top: auto;
    padding: 2rem;
    font-size: $font-size-3;
    font-weight: 600;
    line-height: 2.2rem;
    color: $dark-gray;
    background-color: $white;
    transition: color 100ms linear, background-color 100ms linear;
    border-top: 0.1rem solid $color-6;
    border-radius: 0 0 0.6rem 0.6rem;
    cursor: pointer;
    outline: none;
    user-select: none;

    @include tablet-max {
      font-size: $font-size-2;
      font-weight: 700;
      color: $dark-blue;
    }

    &:hover {
      background-color: $extra-light-purple;
      border-color: $extra-light-purple;
      color: $dark-blue;
    }

    &:focus {
      color: lighten($dark-blue, 5%);
      background-color: $extra-light-purple;
    }

    &:active {
      color: darken($dark-blue, 5%);
      background-color: darken($extra-light-purple, 5%);
    }
  }
  .notifications-panel__placeholder{
    color: #8B8FA4;
    margin: 0 auto;
    font-size: 20px;
    padding-bottom: 3rem;
  }
}
