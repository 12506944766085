@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils.scss";

.user-info-page {
  @include flex($justify-content: space-between);
  
  @include tablet-max {
    flex-direction: column-reverse;
  }

  padding-bottom: 10rem;
  max-width: 95rem;

  &--not-is-player {
    max-width: 63rem;
  }

  &__form {
    margin-bottom: 2rem;
    margin-right: 1rem;
    @include tablet-max {
      margin-right: 0;
      width: 100%;
    }
  }

  &__password-reset {
    margin: 4rem 0;
  }

  &__save-btn {
    margin-top: 4rem;
  }
}

.state-block {
  @include tablet-max {
    width: 100%;
  }

  &__document {
    max-width: 39rem;
    width: 100%;
  }
  &__checkbox {
    padding: 2.4rem;
    margin-bottom: 2rem;
    &_heading {
      margin: 0 0 1.5rem 0;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &_list {
      padding: 0;
      margin: 0;
      list-style: none;
      &_element {
        display: flex;
        align-items: center;
      }
      &_text {
        transform: translate(0, -10%);
        margin-left: 1rem;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
