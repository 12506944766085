@import "src/app/styles/mixins/grid.scss";

.tabs-panel-link {
  @include flex($align-items: center);

  height: 5.6rem;
  color: $black;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;

  &--uppercase {
    text-transform: uppercase;
  }

  &--active {
    color: $dark-blue;
    box-shadow: inset 0px -0.3rem 0px $dark-blue;
  }
}
