@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/mixins/utils";

.newsletter-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  overflow: inherit;
  flex: 1;

  &__buttons {
    display: flex;
    justify-content: flex-end;
    width: 43rem;

    & > *:first-child {
      margin-right: 1rem;
    }
  }

  &__base-select {
    display: flex;
    align-items: center;
    font-size: 1.5rem;

    &-width {
      width: 18rem;
      position: static;
    }

    &-title {
      margin-left: 3rem;
    }
  }

  .base-select {
    position: static;
    flex-shrink: 0;

    &__menu {
      @include tablet-max() {
        width: 18rem;
        left: 2rem;
      }
    }

    > &__menu {
      width: 18rem;

      @include laptop() {
        left: 2.75rem;
      }
    }
  }
}
