@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils";

.base-notification {
  width: 63rem;
  padding: 2rem;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  transition: transform 1s, visibility 0s 1s;
  visibility: hidden;
  transform: translateY(200%);
  z-index: var(--z-index-base-notification);

  background-color: $tooltips-bg-no-blur;
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(1rem);
    background-color: $tooltips-bg;
  }

  @include tablet-max() {
    width: calc(100% - 2rem);
    right: 1rem;
    bottom: 1rem;
  }

  &--visible {
    transition: transform 1s;
    visibility: visible;
    transform: translateY(0);
  }

  &__icon-close {
    position: absolute;
    top: 3rem;
    right: 2rem;
  }

  &__header {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.5rem;
    color: $white;
    margin-bottom: 1.6rem;

    @include tablet-max() {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.4rem;
      margin-bottom: 1.2rem;
    }
  }

  &__body {
    @include flex();
  }

  &__content {
    flex: 1;
    position: relative;
  }

  &__img {
    @include backgroundImage();

    width: 8rem;
    height: 8rem;
    border-radius: $border-radius-small;
    margin-right: 2rem;

    @include tablet-max() {
      width: 6rem;
      height: 6rem;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.5rem;
    color: $blue;

    @include tablet-max() {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  &__description {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.2rem;
    color: $white;
    margin-top: 0.3rem;

    @include tablet-max() {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
  }

  &__params {
    @include flex($align-items: center);

    &-item {
      @include flex();

      font-size: 1.4rem;
      line-height: 1.9rem;
      font-weight: 400;
      color: $extra-light-gray;
      margin-top: 2.2rem;

      &:nth-child(2) {
        &::before {
          @include flex($align-items: center, $justify-content: center);

          content: ".";
          width: 3rem;
          height: 0.6rem;
          font-size: 2rem;
        }
      }
    }
  }

  &__info {
    @include flex($justify-content: space-between, $flex-wrap: nowrap);
  }
}
