.dislike-item {
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    &--bold {
      font-weight: 700;
    }
  }
}
