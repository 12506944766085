.agreement-confirmed {
  &-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
  }
  &-description {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 4rem;
    white-space: pre-wrap;
    text-align: center;
    max-width: 80rem;
    -webkit-font-smoothing: antialiased;
  }
  &-popup {
    z-index: var(--z-index-notifications-panel);
  }
}