@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/mixins/utils";

.my-competence-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.4rem 6rem 2.4rem;

  @include tablet-max {
    max-width: 100%;
  }

  &__list {
    width: 100%;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0 0 20px 0 rgba(40, 58, 151, 0.2);
    border-radius: 2rem;
    background: var(--white);
    max-width: 118.8rem;

    &--additional {
      box-shadow: initial;
      border-radius: initial;
      display: grid;
      margin-bottom: 0;
      grid-template-columns: repeat(1, 1fr);
      background: initial;
    }

    @include tablet-max {
      max-width: 100%;
      min-width: 100%;
      align-items: initial;
      flex-direction: column;
    }

    &-item {
      &--communication {
        order: 1;
        border-right: 1px solid #d4daec;

        @include tablet-max {
          border-bottom: 1px solid #d4daec;
          border-right: initial;
        }
      }

      &--analytics {
        order: 2;
        border-right: 1px solid #d4daec;
        @include tablet-max {
          left: 0;
        }
      }

      &--command {
        order: 3;
      }

      &--jurisprudence {
        order: 1;
      }

      &--thinking3d {
        order: 2;
      }

      &--economics {
        order: 3;
      }
    }

    &-items-wrapper {
      display: flex;

      @include mobile {
        grid-template-columns: repeat(1, 1fr);
      }

      &-additional {
        width: 100%;
        padding: 3rem 0;

        .swiper-wrapper {
          display: flex;
          align-items: stretch;
        }

        .swiper-button {
          &-prev,
          &-next {
            width: 3.6rem;
            height: 3.6rem;
            position: absolute;
            top: 50%;
            right: 0;
            border-radius: 50%;
            background: white;
            backdrop-filter: blur(0.5rem);
            z-index: 10;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 16px 0px rgba(40, 58, 151, 0.2);
            transform: translate(-5%, -50%);
            cursor: pointer;

            &::before {
              @include backgroundImage($background-size: contain);

              background-image: url("../../../shared/images/icons/arrow-blue.svg");
              display: block;
              content: "";
              width: 1.2rem;
              height: 1.2rem;
            }

            @include mobile {
              width: auto;
              border-right: 0;
              padding: 3rem 2rem;
            }

            &:hover {
              opacity: 1;
            }

            @include tablet-max {
              display: none;
            }
          }

          &-prev {
            transform: rotate(180deg) translateY(50%);
            left: 0;

            @include tablet-max {
              left: 0;
            }
          }

          &-disabled,
          &-lock {
            display: none;
          }
        }

        &__item {
          width: auto;
          height: auto;
          display: flex;

          @include mobile {
            transform: scale(0.9);
            transition: 0.3s ease-in-out;

            &.swiper-slide-active {
              transform: scale(1);
            }
          }

          &--economics {
            order: 3;
          }

          &--jurisprudence {
            order: 1;
          }

          &--thinking3d {
            order: 2;
          }

          &--microelectronics {
            order: 4;
          }
        }
      }
    }

    &-competence-group-name {
      display: flex;
      font-weight: 700;
      align-items: flex-end;

      font-size: $font-size-8;
      margin-bottom: 2.4rem;

      @include tablet-max {
        font-size: 2.4rem;
      }

      &--additional {
        margin-bottom: 0;
      }
    }
  }

  &-fine-warning {
    cursor: pointer;

    .base-tooltip {
      max-width: 24rem;
    }
  }

  &__container {
    margin-left: 3rem;
    max-width: 63rem;
    min-width: 50rem;
    width: 100%;

    @include desktop-x-large-max {
      max-width: 100%;
      min-width: 100%;
      margin-left: 0;
    }
  }

  &__information-btn {
    @include backgroundImage($background-size: 0.5rem);
    min-width: 7rem;
    width: 7rem;
    height: 7rem;
    background-color: var(--orange);
    position: fixed;
    right: 4rem;
    bottom: 4rem;
    border-radius: 100%;
    filter: drop-shadow(0 0 1rem rgba(40, 58, 151, 0.3));
    background-image: url("../../../shared/images/icons/information-white.svg");
    z-index: 6;

    @include tablet-max {
      min-width: 5rem;
      width: 5rem;
      height: 5rem;
      background-size: 0.4rem;
      right: 2rem;
      bottom: 2rem;
    }
  }

  &__lvl-up-wrapper {
    display: flex;
    gap: 24px;
    width: 100%;
    margin-bottom: 4rem;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
}
