@import "src/app/styles/mixins/media-queries.scss";

.add-user-page {
  padding-bottom: 10rem;
  width: 100%;
  display: grid;
  gap: 2rem;
  align-items: start;
  grid-template-columns: minmax(min-content, 40.8rem) minmax(25rem, 42rem);
  grid-template-areas:
    "add-user-data add-user-avatar"
    "add-user-data ."
    "add-user-data .";

  @include mobile {
    width: 100%;
    display: flex;
    gap: initial;
    flex-direction: column;
    align-items: stretch;
  }

  &__form {
    grid-area: add-user-data;
  }

  &__form-btn {
    margin-top: 3rem;
  }

  &__rights {
    @include mobile {
      margin-top: 1rem;
    }
  }

  &__set-rights {
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
    margin-bottom: 0.8rem;
  }

  &__photo {
    grid-area: add-user-avatar;
    order: -1;
  }
}
