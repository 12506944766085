@import "src/app/styles/variables/variables";
@import "src/app/styles/mixins/media-queries";
@import "../variables.scss";

.assessment-tile {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__button {
    display: none;
    @extend .base-notification-on-page-button;

    &--showed {
      display: block;
    }
  }

  &__button {
    margin-left: auto;
  }
}

.assessment-header {
  &__simulation-title {
    color: $text-color-primary;
    font-size: $font-size-4;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 2.5rem;
    margin-bottom: 0.3rem;

    @include mobile() {
      font-size: $font-size-3;
      line-height: 2.2rem;
      margin-bottom: 0.5rem;
    }
  }

  &__title {
    font-size: $font-size-3;
    line-height: 2.2rem;
    margin-bottom: 1.4rem;

    @include mobile() {
      font-size: $font-size-2;
      line-height: 1.9rem;
      margin-bottom: 0.5rem;
    }
  }
}

.assessment-participation {
  margin-bottom: 1rem;

  @include mobile() {
    margin-bottom: 0.5rem;
  }

  &__title,
  &__description {
    font-size: $font-size-3;

    @include mobile() {
      font-size: $font-size-2;
    }
  }

  &__title {
    margin-bottom: 0.8rem;

    @include mobile() {
      margin-bottom: 0.5rem;
    }
  }
}

.assessment-content {
  &__types {
    font-size: $font-size-2;
    line-height: 1.9rem;
    letter-spacing: 0em;
    color: $extra-light-gray;
    display: flex;
    align-items: center;

    @include mobile() {
      font-size: $font-size-1;
      line-height: 1.6rem;
    }

    .assessment-content__type {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        &::before {
          $size: 0.3rem;

          display: block;
          content: "";
          width: $size;
          height: $size;
          background: $extra-light-gray;
          border-radius: 50%;
          margin: 0 1rem;
        }
      }
    }
  }

  &__button {
    margin-top: 1.8rem;
  }
}
