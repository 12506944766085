@import "src/app/styles/mixins/grid";

.policy {
  &:first-child{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .policy__title {
        margin-right: 133px;
      }
    }

  &:not(:last-child) {
    margin-bottom: 5rem;
  }

  &__title {
    margin-bottom: 2rem;
    font-weight: 800;
    font-size: 2.2rem;
    line-height: 2.2rem;
    color: $black;
    text-align: center;
  }

  &__list {
    list-style: none;

    &-item {
      font-weight: 600;
      text-indent: 3.5rem;
      text-align: justify;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $black;

      &:not(:last-child) {
        margin-bottom: 0.7rem;
      }
    }
  }
}
