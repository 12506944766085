@import "src/app/styles/mixins/grid";

@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/mixins/competence-colors";

.custom-scale {
  margin-bottom: 2.5rem;
  height: 2.6rem;
  position: relative;

  @include tablet-max() {
    height: 1.5rem;
  }

  &__numbers {
    display: flex;
    justify-content: space-between;
    margin-top: 0.8rem;
  }

  &__progress-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    border-radius: 10rem;
  }

  &__main,
  &__progress {
    @include flex($align-items: center, $justify-content: space-evenly);

    padding: 0 1.1rem;
    width: var(--custom-scale-width--main-competence);

    &--additional-competence {
      width: var(--custom-scale-width--additional-competence);
    }

    height: 100%;
    border-radius: 10rem;

    @include tablet-max() {
      padding: 0 1.5rem;
      width: calc(100vw - 6.8rem);
      justify-content: space-between;
    }

    @include mobile {
      width: calc(100vw - 5rem);
    }
  }

  &__main {
    background: var(--competence-scale-bg);

    &-item {
      background: var(--competence-scale);
      position: relative;
    }
  }

  &__progress {
    &--communication {
      background: $blue;
    }

    &--analytics {
      background: $purple;
    }

    &--command {
      background: $sea-wave;
    }

    &--thinking3d {
      background: $pale-orange;
    }

    &--economics {
      background: $pink;
    }

    &--jurisprudence {
      background: $bright-green;
    }

    &--microelectronics {
      background: $teal;
    }

    &-item {
      background: $white;
    }
  }
}

.custom-scale-wrapper--mini-view {
  .custom-scale {
    @include competenceColors(background, 20%);
    height: 1rem;
    border-radius: 8px;

    &__progress-bar {
      @include competenceColors(background);
      border-radius: inherit;
      height: 100%;
    }
  }
}
