@import "../../../app/styles/mixins/grid";
@import "../../../app/styles/mixins/media-queries";
@import "../../../app/styles/mixins/utils";

.page-wrapper {
  @include flex($flex-direction: column, $align-items: stretch);

  min-height: 100vh;

  &--white-bg {
    background: white;
  }

  .table__control {
    @include flex($justify-content: center, $align-items: center);

    &--in-popup {
      margin: 3rem 0 5rem 0;
    }
  }

  .table__control-btn {
    cursor: pointer;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &--hide-on-mobile {
      @include mobile {
        display: none;
      }
    }

    &--hide-on-not-mobile {
      @include tablet {
        display: none;
      }
    }
  }

  &__container {
    position: relative;
    background: $white;

    @include tablet-max {
      transition: transform 0.3s;
      position: sticky;
      top: $header-height;
      left: 0;
      z-index: var(--z-index-page-wrapper);

      &--header-hidden {
        transform: translateY(-$header-height);
      }
    }

    &-loader {
      width: 100%;
      height: 0.6rem;
      position: absolute;
      bottom: 0;
      left: 0;
      background: $extra-light-gray;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $blue;
        animation: loading 30s linear;
      }

      @keyframes loading {
        from {
          width: 0;
        }

        10% {
          width: 67%;
        }

        to {
          width: 100%;
        }
      }
    }
  }

  &__additional-panel {
    padding: 1rem 2.8rem;
    background: #ffffff;
    box-shadow: 0rem 0.0313rem 0rem #e0e4f8;
  }

  &__title-panel {
    @include flex($align-items: center, $justify-content: space-between);

    padding: 3.2rem 3rem 0.6rem;

    @include tablet-max() {
      padding: 2.1rem 2rem 0.2rem;
      transition: 0.3s;
    }

    &--empty-base-panel {
      padding: 0 3rem;
      min-height: 10.2rem;

      @include tablet-max() {
        padding: 0 2rem;
        min-height: 6rem;
      }
    }
  }

  &__title {
    @include flex($align-items: center);

    font-weight: 800;
    font-size: 2.8rem;
    line-height: 3.8rem;
    color: $black;

    @include tablet-max() {
      font-size: 1.8rem;
      line-height: 1.8rem;
    }

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__title-sub-item {
    margin-left: $gutter/2;
  }

  &__back {
    @include backgroundImage($background-size: contain);

    margin-right: 2.5rem;
    min-width: 2.4rem;
    width: 2.4rem;
    height: 2.4rem;
    background-image: url("../../images/icons/arrow-back-blue.svg");
    border: 0;
    outline: 0;
    background-color: transparent;
    cursor: pointer;

    @include tablet-max() {
      margin-right: 1.4rem;
      min-width: 1.6rem;
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &__content {
    @include flex($flex-direction: column, $align-items: stretch);

    flex-grow: 1;
    overflow: hidden;

    //noinspection CssReplaceWithShorthandSafely
    padding: 1rem;

    //noinspection CssReplaceWithShorthandSafely
    padding-bottom: calc(env(safe-area-inset-bottom) + 1rem);

    @include laptop {
      padding: 3rem;
    }

    &--without-padding {
      padding: 0;
    }

    &--lightblue-background {
      background: $lightest-gray;
    }

    &--my-competence-page {
      flex-direction: row;
      justify-content: center;
      background: #ededf3;
    }
  }
}
