@import "src/app/styles/mixins/media-queries";

.notifications-button {
  position: relative;
  min-width: 3.4rem;
  width: 3.4rem;
  height: 3.4rem;
  //background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  border-radius: 50%;
  transition: background-color 0.5s ease;

  @include desktop-large-max {
    min-width: 3.6rem;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
  }

  @include tablet-max {
    min-width: 3.4rem;
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &--active &__icon {
    animation: activeBell 0.5s forwards;
  }

  &--hideMobile {
    @include tablet-max {
      display: none;
    }
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  @include mobile {
    &:hover:is(&--disabled) {
      background-color: transparent;
    }
  }
  @include tablet {
    &:hover:is(&--disabled) {
      background-color: transparent;
    }
  }

  &--disabled &__icon {
    animation: inactiveBell 0.5s forwards;
  }
  &--active {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &__icon {
    color: $white;
  }

  &__counter {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.4rem;
    height: 1.4rem;
    top: 0;
    right: 0;
    padding: 0;
    font-size: 0.8rem;
    line-height: normal;
    border-radius: 50%;
    color: $white;
    background-color: $red;

    @include desktop-large-max {
      width: 1.8rem;
      height: 1.8rem;
      bottom: 2.5rem;
      left: 2.5rem;
      font-size: 0.8rem;
      line-height: 0.8rem;
    }

    @include tablet-max {
      width: 0.8rem;
      height: 0.8rem;
      color: transparent;
      outline: none;
    }
  }
  @keyframes activeBell {
    0% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(55deg);
    }
    70% {
      transform: rotate(50deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  @keyframes inactiveBell {
    0% {
      transform: rotate(45deg);
    }
    40% {
      transform: rotate(-15deg);
    }
    70% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
