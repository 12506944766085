@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";

.base-panel {
  padding: 0 2.8rem;
  width: 100%;
  border-bottom: 0.1rem solid $color-6;

  @include tablet-max {
    padding: 0 2rem;
  }

  &__children {
    @include flex($align-items: center, $justify-content: space-between);

    width: 100%;
    height: 7.3rem;

    @include tablet-max {
      height: 5.7rem;
      transition: height 0.3s, opacity 0.2s 0.1s;
    }

    &--empty {
      overflow: initial;
    }

    &--header-hidden {
      @include tablet-max {
        transition: height 0.3s, opacity 0.2s;
        opacity: 0;
        height: 0;
      }
    }
  }

  overflow-x: auto;
}
