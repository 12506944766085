@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/utils";

.base-avatar {
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  display: inline-block;
  background-color: #e8eefe;
  user-select: none;
}

.base-avatar__admin {
  border: 2px solid #FFD602;
}
.base-avatar__hr {
  border: 2px solid #72FB02;
}
