@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/font.scss";
@import "src/app/styles/mixins/utils.scss";

.contact-card {
  @include flex();

  padding: 2.4rem;
  width: 100%;
  height: 100%;

  @include tablet-max {
    padding: 1.5rem;
    position: relative;
  }

  &__image {
    @include backgroundImage();

    min-width: 15.6rem;
    width: 15.6rem;
    height: 18.6rem;
    margin-right: 2rem;
    border-radius: $border-radius-small;

    @include tablet-max {
      margin-right: 1.4rem;
      min-width: 9rem;
      width: 9rem;
      height: 12.6rem;
    }

    @include mobile-small {
      display: none;
    }
  }

  &__info {
    @include flex($flex-direction: column, $justify-content: space-between);

    height: 100%;

    &-block {
      @include flex($flex-direction: column);
    }
  }

  &__position {
    margin-bottom: 0.6rem;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $gray;

    @include tablet-max {
      margin-bottom: 0.5rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  &__name {
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.8rem;
    color: $black;

    @include tablet-max {
      margin-bottom: 1.5rem;
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  &__link {
    margin-bottom: 0.8rem;
    text-decoration: none;
  }

  &__button {
    margin-top: 2rem;

    &:first-child {
      margin-right: 2rem;
    }
  }
}
