@import "src/app/styles/variables/variables";
@import "src/app/styles/mixins/grid";

.playerCard {
  background: $neutral-n-0;
  border-radius: $border-radius;
  padding: .8rem 2rem .8rem .8rem;
  box-shadow: 0 0 2rem 0 rgba(40, 58, 151, 0.20);

  @include flex(center, flex-start);
  gap: .8rem;

  .name {
    margin-left: .4rem;
    text-overflow: ellipsis;
    font-size: $font-size-3;
    font-weight: 600;
    text-decoration: underline;
  }

  .desiredRole {
    font-size: $font-size-2;
    color: $neutral-n-100;
    font-weight: 400;
  }

  .buttonCross {
    margin-left: auto;
  }

  .dragIcon {
    cursor: grab;
  }

  &.transparent {
    opacity: .5;
  }

  &.isDragging {
    opacity: 40%;
  }
}