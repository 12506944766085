@import "src/app/styles/variables/variables.scss";

.assessments-table-session-title {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.assessments-table-copy-parent::after {
  content: "";
  display: block;
  height: 0.8rem;
  width: 0.8rem;
  aspect-ratio: 1;
  flex-shrink: 0;
  background: $blue-b-100;
  border-radius: 1rem;
}
