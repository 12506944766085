@import "src/app/styles/variables/variables";
@import "src/app/styles/mixins/media-queries";
@import "../variables.scss";

.news-tile {
  max-width: 100%;

  &__title {
    font-size: $font-size-4;
    font-weight: 700;
    line-height: 2.5rem;
    margin-bottom: 0.6rem;

    @include mobile() {
      font-size: $font-size-2;
      line-height: 1.9rem;
    }
  }

  &__description {
    font-size: $font-size-3;
    font-weight: 400;
    line-height: 2.2rem;
    max-width: 63rem;

    @include mobile() {
      font-size: $font-size-1;
      line-height: 1.6rem;
    }
  }

  &__link {
    font-size: $font-size-3;
    font-weight: 600;
    line-height: 2.2rem;
    margin-top: 1rem;
    color: $text-color-primary;

    @include mobile() {
      font-size: $font-size-2;
      line-height: 1.9rem;
    }

    &:hover,
    &:active,
    &:focus,
    &:visited {
      color: inherit;
    }
  }
}
