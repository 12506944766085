@import "src/app/styles/variables/variables";

.simulation-links-block {
  &__title {
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $color-26;
  }
}
