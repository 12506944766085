@import "src/app/styles/variables/variables.scss";

.svg-icon {
  &__item {
    &--black {
      fill: $black;
    }

    &--red {
      fill: $red;
    }

    &--blue {
      fill: $dark-blue;
    }

    &--gray {
      fill: $gray;
    }
  }

  &__item-stroke {
    &--black {
      stroke: $black;
    }

    &--red {
      stroke: $red;
    }

    &--blue {
      stroke: $dark-blue;
    }

    &--gray {
      stroke: $gray;
    }
  }
}
