@import "src/app/styles/mixins/media-queries";

.send-notification-popup {
  padding: 2.6rem;
  width: 38rem;
  color: var(--black);

  @include mobile {
    width: 100%;
  }

  &__title {
    margin-bottom: 3rem;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.5rem;
    text-align: center;
  }

  &-type {
    margin-bottom: 3rem;

    &__title {
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.9rem;
    }

    &__items {
      max-width: 34rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      &-checkbox {
        margin-top: 1.2rem;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }

  &__textarea {
    margin-bottom: 3rem;
  }
}
