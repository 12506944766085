@import "src/app/styles/variables/variables";
@import "src/app/styles/mixins/grid";

.head {
  @include flex($justify-content: space-between, $align-items: center);

  width: 100%;
  height: 5.2rem;
  padding: 0 2rem;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  color: $gray;

  .team {
    justify-content: space-between;
    padding: 0 3rem;
  }

  .item {
    .wide {
      margin-left: 12rem;
    }
  }
}