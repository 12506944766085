@import "src/app/styles/mixins/media-queries.scss";

.created-assessments {
  &-page {
    &__assessment-members {
      width: 56rem;

      @include tablet-max {
        width: 100%;
      }
    }
  }

  &-data-grid-wrapper {
    height: calc(100vh - 26.1rem);

    @include mobile-landscape {
      height: initial;
    }

    @include tablet-landscape {
      height: initial;
    }

    @include laptop {
      height: calc(100vh - 29rem);
    }
  }
}
