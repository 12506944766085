.base-dropdown {
  position: relative;
  overflow: visible;

  &__view {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__content {
    position: absolute;
    transition: 0.5s;
    visibility: hidden;
    transform: scale(0);
    opacity: 0;
    z-index: var(--z-index-base-popup);

    margin-top: 1rem;
    background: white;
    border-radius: 6px;
    box-shadow: 0 0 30px 0 rgba(74, 91, 101, 0.2);
    display: block;
    width: max-content;

    &--active {
      opacity: 1;
      transform: scale(1);
      visibility: visible;
    }

    &--align-left {
      left: 0;
      transform-origin: top left;
    }

    &--align-right {
      right: 0;
      transform-origin: top right;
    }

    &--position-top {
      transform: translateY(calc(-100% - 4rem)) scale(0);

      &.base-dropdown__content--active {
        transform: translateY(calc(-100% - 4rem)) scale(1);
      }

      &.base-dropdown__content--align-left {
        transform-origin: bottom left;
      }

      &.base-dropdown__content--align-right {
        transform-origin: bottom right;
      }
    }

    &--long {
      max-height: calc(100svh - 12rem);
      overflow-y: scroll;
    }
  }
}
