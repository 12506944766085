@import "src/app/styles/mixins/media-queries";

.competence-control {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__container {
    display: flex;
    align-items: center;
  }

  &__playersCount {
    margin-top: 8px;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: $gray;
  }

  &__button {
    display: flex;
    justify-content: flex-end;
  }

  &__list {
    list-style: none;

    &-item {
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: var(--black);
      margin-right: 1.6rem;

      @include mobile {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }

      &:not(:last-child) {
        margin-bottom: 2rem;

        @include mobile {
          margin-bottom: 1.7rem;
        }
      }
    }
  }
}
