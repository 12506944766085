@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/utils";

.image-with-empty {
  @include backgroundImage();

  min-width: 100%;
  width: 100%;
  height: 100%;

  &--circle-radius {
    border-radius: 100%;
  }

  &__empty-img {
    min-width: 100%;
    width: 100%;
    height: 100%;

    &--cover {
      background-size: cover;
    }

    &--contain {
      background-size: contain;
    }

    &--user {
      background-image: url("../../images/avatar-empty.svg");
    }

    &--simulation {
      background-image: url("../../images/simulation-empty.svg");
    }

    &--plus {
      background-image: url("../../images/base-card-create.svg");
    }
  }

  &__main-img {
    @include backgroundImage();

    min-width: 100%;
    width: 100%;
    height: 100%;

    &--cover {
      background-size: cover;
    }

    &--contain {
      background-size: contain;
    }
  }
}
