@import "src/app/styles/mixins/utils";
@import "src/app/styles/variables/variables";

.params-info-item {
  display: flex;

  &--column {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &__title {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: var(--black);

    &--bold {
      font-weight: bold;
    }

    &--icon {
      display: flex;
      align-items: center;
      font-weight: 600;

      &::before {
        @include backgroundImage($background-size: contain);

        content: "";
      }

      &_checked {
        &::before {
          margin-right: 1.5rem;
          min-width: 1.4rem;
          width: 1.4rem;
          height: 1.4rem;
          background-image: url("../../images/icons/checkbox-check.svg");
        }
      }

      &_unchecked {
        &::before {
          margin-right: 1.9rem;
          min-width: 1.2rem;
          width: 1.2rem;
          height: 1.2rem;
          background-image: url("../../images/icons/cross-gray.svg");
        }
      }
    }
    &--check {
      cursor: pointer;
      user-select: none;
    }
  }

  &__description {
    margin-top: 0.6rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: var(--blue);

    &--bold {
      font-weight: 600;
    }

    &--row {
      margin-top: 0;
      margin-left: 2.5rem;
    }

    &--no-wrap {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
    }

    &--input {
      max-width: 100%;
      border: none;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: var(--blue);
      cursor: pointer;
      &:focus {
        outline: none;
        cursor: text;
      }
    }
  }

  &__sub-description {
    margin-top: 1.6rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: var(--gray);
    &_editable {
      margin-top: 0;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(4rem, 1fr));
    gap: 0 1.6rem;

    &_editable {
      margin-top: 1.8rem;
      grid-template-columns: repeat(auto-fill, minmax(7.2rem, 1fr));
      gap: 0.8rem 0.8rem;
    }

    &--column {
      display: flex;
      flex-direction: column;
    }

    &-item {
      display: flex;
      flex-direction: column;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__wrapper_editable {
    padding: 0.8rem 0 0.8rem 1.2rem;
    max-width: 7.2rem;
    border-radius: 0.6rem;
    border: 1px solid $extra-light-gray;
  }
  &__add_turn {
    transform: translate(20%, 0);
  }
  &__add_turn_btn {
    width: 7.2rem;
  }
  &__delete_turn {
    margin-top: 0.6rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $red;
    cursor: pointer;
    user-select: none;
  }
}
