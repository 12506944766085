@import '../mixins/media-queries';

.d {
  &-none {
    &-mobile {
      &--down {
        @include mobile {
          display: none;
        }
      }
      &--up {
        @include tablet {
          display: none;
        }
      }
    }
  }
}
