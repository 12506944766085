@import "../../../app/styles/mixins/grid";
@import "../../../app/styles/mixins/media-queries";
@import "../../../app/styles/mixins/utils";
@import "../../../app/styles/variables/variables";

.pageWrapper {
  background: $page-wrapper-bg;
  min-height: 100vh;

  @include flex(center, flex-start, nowrap, column);

  @include laptop {
    padding: 4rem;

    &.blank {
      padding: 0;
    }
  }

  @include mobile {
    min-height: inherit;
  }

}

.container {
  position: relative;
  background: white;

  &.blank {
    background: none;
  }
  @include laptop {
    border-radius: $border-radius-large;
    box-shadow: 0 0 2rem 0 rgba($dark-blue, 13%);
    width: 100%;
    max-width: 119rem;

    &.blank {
      border-radius: 0;
      box-shadow: none;
      max-width: 127rem;
    }
  }

  @include tablet-max {
    transition: transform 0.3s;
    position: sticky;
    top: $header-height;
    left: 0;
    z-index: var(--z-index-page-wrapper);
    width: 100%;
    &.header-hidden {
      transform: translateY(-$header-height);
    }
  }

  &Loader {
    width: 100%;
    height: 0.6rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $extra-light-gray;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $blue;
      animation: loading 30s linear;
    }

    @keyframes loading {
      from {
        width: 0;
      }

      10% {
        width: 67%;
      }

      to {
        width: 100%;
      }
    }
  }
}

.additionalPanel {
  padding: 1rem 2.8rem;
  background: white;
  box-shadow: 0 0.0313rem 0 #e0e4f8;
}

.titlePanel {
  @include flex($align-items: center, $justify-content: space-between);

  border-bottom: 1px solid #d4daec;
  padding: 2.6rem 4rem;

  @include tablet-max() {
    transition: 0.3s;
  }
}

.title {
  @include flex($align-items: center);

  font-weight: 700;
  font-size: 2rem;
  color: $black;

  @include tablet-max() {
    font-size: 1.8rem;
  }

  &:not(:last-child) {
    margin-right: 2rem;
  }
}

.titleSubItem {
  margin-left: $gutter/2;
}

.backButton {
  @include backgroundImage($background-size: contain);

  margin-right: 2.5rem;
  min-width: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  background-image: url("../../images/icons/arrow-back-blue.svg");
  border: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;

  @include tablet-max() {
    margin-right: 1.4rem;
    min-width: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
  }
}

.content {
  @include flex($flex-direction: column, $align-items: stretch);

  flex-grow: 1;
  overflow: hidden;

  padding: 2.4rem 2.4rem 10rem 2.4rem;


  @include laptop {
    padding: 4rem 4rem 10rem 4rem;

    &.blank {
      padding: 4rem;
    }
  }

  &.withoutPadding {
    padding: 0;
  }

  &.myCompetencePage {
    flex-direction: row;

    @include desktop-x-large-max {
      flex-direction: column;
    }
  }
}
