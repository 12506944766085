@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/variables/variables";

.assessment-card {
  color: $black;

  &__connect-alert {
    margin-top: 1.5rem;
    padding: 1rem 1.5rem;
    font-size: $font-size-2;
    line-height: 1.9rem;
    background: $lightest-gray;
    color: $dark-gray;
    max-width: 45.5rem;

    @include mobile() {
      padding: 0.8rem 1.2rem;
      font-size: $font-size-1;
      line-height: 1.6rem;
    }
  }

  &__connect-button {
    margin-top: 1.8rem;

    @include mobile() {
      width: 100%;
    }
  }

  &__participation {
    margin-top: 1rem;

    @include mobile() {
      margin-top: 0.5rem;
    }

    &-title,
    &-description {
      font-size: $font-size-3;

      @include mobile() {
        font-size: $font-size-2;
      }
    }

    &-title {
      margin-bottom: 0.8rem;

      @include mobile() {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.assessment-card-names {
  color: $black;

  &__simulation-name {
    font-size: $font-size-4;
    font-weight: 800;
    line-height: 2.5rem;
    color: $dark-blue;
    text-transform: uppercase;

    @include mobile() {
      font-size: $font-size-3;
      line-height: 2.2rem;
    }
  }

  &__name {
    font-size: $font-size-3;
    line-height: 2.2rem;
    font-weight: 400;

    @include mobile() {
      font-size: $font-size-2;
      line-height: 1.9rem;
    }
  }
}

.assessment-card-statuses {
  $text-color: $gray;

  display: flex;
  margin-top: 1.4rem;
  font-size: $font-size-2;
  line-height: 1.9rem;
  color: $text-color;

  @include mobile() {
    margin-top: 0.5rem;
    font-size: $font-size-1;
    line-height: 1.6rem;
  }

  & > *:last-child {
    display: inline-flex;
    align-items: center;

    &:not(:only-child)::before {
      $size: 0.3rem;

      content: "";
      width: $size;
      height: $size;
      border-radius: 50%;
      background: $text-color;
      margin: 0 1rem;
    }
  }
}

.assessment-card-modes {
  margin-top: 0.7rem;

  @include mobile() {
    margin-top: 0.4rem;
  }

  &__title {
    opacity: 0.8;
    font-size: $font-size-2;
    line-height: 1.9rem;
    color: $gray;

    @include mobile() {
      font-size: $font-size-1;
      line-height: 1.6rem;
    }
  }

  &__mode {
    font-size: $font-size-3;
    font-weight: 700;
    line-height: 2.2rem;
    color: $green;
    text-transform: uppercase;
    margin-top: 0.4rem;

    @include mobile() {
      font-size: $font-size-2;
      line-height: 1.9rem;
    }
  }
}

.assessment-card-badge {
  font-size: $font-size-2;
  line-height: 1.9rem;
  padding: 0.4rem 1.2rem;
  box-sizing: border-box;
  border-radius: 0.4rem;
  width: fit-content;
  margin-left: auto;

  @include mobile() {
    font-size: $font-size-1;
    line-height: 1.6rem;
    padding: 0.4rem 0.8rem;
  }

  &--wait {
    color: $orange;
    background: $extra-light-orange;
  }

  &--launched {
    color: $green;
    background: $light-green;
  }

  &--completed {
    background: $extra-light-gray;
    color: $gray;
  }

  &--results {
    background: $extra-light-purple;
    color: $dark-blue;
    font-weight: 700;
    cursor: pointer;
  }
}

.assessment-card-results {
  position: absolute;
  width: fit-content;
  top: 6rem;
  right: 0;

  @include mobile() {
    top: 1.5rem;
  }

  &__popup {
    visibility: hidden;
  }

  &:hover &__popup {
    visibility: visible;
  }
}

.assessment-card-share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem;
  box-sizing: border-box;
  background-color: $lightest-gray;
  border-radius: 0.6rem;
  margin-top: 1.5rem;

  &__text {
    color: $dark-blue;
    font-size: $font-size-2;
    line-height: 1.9rem;
    font-weight: 700;

    @include mobile() {
      font-size: $font-size-1;
      line-height: 1.6rem;
    }
  }

  &__buttons-group {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__button {
    $size: 3rem;

    width: $size;
    height: $size;
    border-radius: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $extra-light-purple;

    & * {
      margin: 0;
    }
  }
}

.card-results-popup {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem 1.2rem;
  gap: 0.8rem;
  background: $tooltips-bg;
  backdrop-filter: blur(10px);
  border-radius: 0.6rem;
  color: $extra-light-gray;
  text-transform: capitalize;
  font-weight: 400;
  font-size: $font-size-1;
  line-height: 1.6rem;
  min-width: 21.5rem;
  box-sizing: border-box;

  @include mobile() {
    max-width: 23.5rem;
  }

  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.card-popup-row {
  &__value {
    color: $white;

    &--red {
      color: $red;
    }

    &--green {
      color: $green;
    }

    &--empty {
      color: $dark-gray;
    }
  }
}
