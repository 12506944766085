@import "src/app/styles/mixins/grid";

.comment-item {
  @include flex;
  gap: 1rem;
  margin-top: 2rem;

  &--pending {
    filter: opacity(0.5);
  }

  &__avatar-image {
    display: block;
    border-radius: 100%;
    width: 5.6rem;
    height: 5.6rem;
  }

  &__comment {
    width: 100%;
    @include flex(unset, unset, nowrap, column);
    gap: 1rem;
  }

  &__author-and-time {
    @include flex(flex-end, flex-start, nowrap, row);
    gap: 1rem;
  }

  &__author {
    font-size: var(--font-size-3);
    font-weight: 600;
    color: var(--black);
    text-decoration: none;
  }

  &__time {
    font-size: var(--font-size-2);
    color: var(--gray);
  }

  &__body {
    border: 1px solid var(--extra-light-gray);
    border-radius: var(--border-radius-small);
    padding: 1.5rem 1rem;
    font-size: var(--font-size-2);
    width: 100%;

    &--editing {
      border-color: var(--dark-blue);
      outline: none;
    }
  }

  &__controls {
    @include flex(center, flex-start, nowrap, row);
    gap: 1rem;

    &-button {
      color: var(--gray);
      text-decoration: underline;
      font-size: var(--font-size-2);
      font-weight: 400;
    }
  }
}
