@import "../../../../app/styles/mixins/grid";
@import "../../../../app/styles/mixins/media-queries";

.base-message-popup {
  @include flex($flex-direction: column, $align-items: center);

  max-width: 35rem;
  width: 100%;

  @include tablet-max {
    max-width: 100%;
  }

  &__description {
    margin-bottom: 3rem;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.2rem;
    text-align: center;
    color: $dark-blue;

    &--error {
      color: $red;
    }
  }
}
