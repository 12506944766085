@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils.scss";

.info-page {
  @include flex($flex-direction: column);

  border-radius: 2rem;
  box-shadow: 0 0 2rem 0 rgba(40, 58, 151, 0.13);
  width: 100%;
  max-width: 119rem;
  background: white;

  &_title {
    @include flex($align-items: center, $justify-content: space-between);

    border-bottom: 1px solid #d4daec;

    padding: 2.6rem 4rem;

    width: 100%;

    @include tablet-max() {
      transition: 0.3s;
    }

    &__text {
      @include flex($align-items: center);

      font-weight: 700;
      font-size: 2rem;
      color: $black;

      @include tablet-max() {
        font-size: 1.8rem;
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }
      }
    }

  color: $black;

  //noinspection CssOverwrittenProperties
  @include mobile {
    padding-bottom: (5.6rem + 2rem + 0.5rem);
    padding-bottom: calc(
      env(safe-area-inset-bottom) + #{5.6rem + 2rem + 0.5rem}
    );
  }

  &__resume {
    margin-top: 1.2rem;
  }

  &__description-wrap {
    margin-bottom: 2.5rem;
  }

  &__description {
    max-width: 70rem;
    padding: 1rem 1.2rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $dark-gray;
  }

  &__data {
    @include flex(initial, initial, nowrap, column);
    gap: 2.4rem;

    @include laptop {
      flex: 1;
    }

    @include tablet-max {
      width: 100%;
    }

    @include mobile {
      margin-bottom: 1rem;
    }

    &-form {
      width: 100%;

      @include laptop {
        flex: 2;
      }
    }

    &-title {
      margin-bottom: 3rem;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 2rem;
      color: $black;
    }

    &-btn {
      @include mobile {
        position: fixed;
        z-index: 1;
        left: 1rem;
        width: calc(100% - 2rem);

        //noinspection CssOverwrittenProperties
        bottom: 1.5rem;
        //noinspection CssOverwrittenProperties
        bottom: calc(env(safe-area-inset-bottom) + 1.5rem);
      }
    }
  }

  &__avatar {
    @include mobile {
      order: -1;
      margin-bottom: 1rem;
    }
  }

  &__apply_papers {
    margin-top: 1.2rem;
    padding: 1.2rem 2.4rem;
    background: white;

    & > a {
      text-decoration: none;
    }
  }

  &__show-rights {
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
  }

  &__reset {
    @include mobile {
      margin-top: 1rem;
    }
  }

  &__form {
    display: flex;
    gap: 2.8rem;
    width: 100%;
    padding: 4rem 4rem 10rem 4rem;

    @include mobile {
      gap: initial;
      flex-direction: column;
      padding-bottom: 0;
      align-items: stretch;
    }

    &-control {
      width: 100%;
    }

    &-control:not(:last-of-type) {
      margin-bottom: 2rem;
    }

    &-change-password {
      margin: 4rem 0;
      text-decoration: none;

      &--blocked {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }

    &-upload {
      @include flex($flex-direction: column);

      max-width: 38rem;
      width: 100%;

      @include tablet-max {
        max-width: 100%;
      }

      &-container {
        @include tablet-max {
          display: grid;
          grid-template-areas:
            "avatar-photo  avatar-description"
            "avatar-button avatar-button";
        }
      }

      &-title {
        margin-bottom: 1.5rem;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 2rem;
        color: $black;
      }

      &-description {
        margin-bottom: 1.8rem;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: (19/14);
        color: $dark-gray;

        &-item:not(:last-child) {
          margin-bottom: 0.7rem;
        }
      }

      &-img {
        @include backgroundImage();

        min-width: 13.2rem;
        width: 13.2rem;
        height: 13.2rem;
        position: relative;
        border-radius: 0.6rem;
        margin-top: 2rem;

        @include tablet-max {
          margin-right: 2.5rem;
          margin-top: 0;
          margin-bottom: 2rem;
          min-width: 11rem;
          width: 11rem;
          height: 11rem;
        }

        &--empty {
          border: 0.1rem solid $extra-light-gray;
        }
      }

      &-delete-avatar {
        @include backgroundImage();

        position: absolute;
        top: -0.9rem;
        right: -0.9rem;
        width: 2.4rem;
        height: 2.4rem;
        background-image: url("../../../shared/images/icons/icon-close-red.svg");
        cursor: pointer;
      }

      &-input {
        @include flex($align-items: center);

        &-label {
          @include flex($align-items: center);

          border: 0;
          outline: 0;
          background: transparent;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 1.9rem;
          color: $dark-blue;
          cursor: pointer;

          &--delete {
            margin-left: 2rem;
          }

          &--file {
            margin-bottom: 2rem;
            text-decoration: none;
          }
        }

        &-field {
          display: none;
        }
      }
    }
  }
}
