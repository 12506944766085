@import "src/app/styles/mixins/grid";

@import "src/app/styles/mixins/media-queries";

@import "src/app/styles/mixins/utils";

.base-popup {
  @include flex(
    $align-items: center,
    $justify-content: center,
    $flex-direction: column
  );

  padding-top: 6.7rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($dark-blue, 0.4);
  opacity: 0;
  z-index: var(--z-index-base-popup);
  transition: 0.5s;
  animation: popup-bg-show 0.5s;

  @keyframes popup-bg-show {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @include mobile {
    padding-left: 0;
  }

  &--only-mobile {
    @include tablet-max {
      padding-left: 0;
    }
  }

  &--showed {
    opacity: 1;
  }

  &--mobile-bottom {
    @include mobile {
      justify-content: flex-end;
    }
  }
  &--only-mobile {
    @include tablet-max {
      justify-content: flex-end;
    }
  }

  &--transparent-overflow {
    background: transparent;
  }

  &__swipe {
    width: 100%;
    display: none;
    position: absolute;
    left: 0;
    top: 0.8rem;
    z-index: 700;

    &::before {
      margin: 0 auto;
      content: "";
      display: block;
      width: 3.6rem;
      height: 0.4rem;
      background: #8b8fa4;
      border-radius: 10rem;
      transition: 0.3s;
    }

    &--catch::before {
      width: 10rem;
    }

    @include mobile {
      display: block;
    }

    &--only-mobile {
      @include tablet-max {
        display: block;
      }
    }
  }

  &__content {
    @include flex($flex-direction: column);

    max-width: 95%;
    max-height: 90%;
    box-shadow: 0 0 5rem rgba(100, 163, 198, 0.1);
    border-radius: $border-radius-large;
    position: relative;
    transition: 0.5s;
    transform: translateY(100%);
    animation: popup-show 0.5s;

    @keyframes popup-show {
      from {
        transform: translateY(100%);
      }

      to {
        transform: translateY(0);
      }
    }

    &--mobile-bottom {
      @include mobile {
        max-height: calc(100% - 12rem);
        border-radius: 1.8rem 1.8rem 0 0;
        max-width: 100%;
        width: 100%;
      }
    }
    &--only-mobile {
      @include tablet-max {
        max-height: calc(100% - 12rem);
        border-radius: 1.8rem 1.8rem 0 0;
        max-width: 100%;
        width: 100%;
      }
    }

    &--showed {
      transform: translateY(0);
    }

    &--white {
      background: $white;
    }

    &--black {
      background-color: $tooltips-bg-no-blur;
      color: $white;

      @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        backdrop-filter: blur(1rem);
        background-color: $tooltips-bg;
      }
    }

    &--full-height {
      height: 100%;
    }
  }

  &__title {
    padding: 3rem 5rem;
    width: 100%;
    height: 8.7rem;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.7rem;
    text-align: center;
    color: $black;
    user-select: none;
    position: relative;

    @include mobile {
      padding: 2rem 2.4rem 2.4rem 2.4rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    &--only-mobile {
      @include tablet-max {
        padding: 2rem 2.4rem 2.4rem 2.4rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }

    &--white {
      color: $white;
    }

    &--no-padding {
      padding-bottom: 1rem;
      height: auto;
    }
  }

  &__close-btn {
    position: absolute;
    right: 3rem;
    top: 3.6rem;

    @include mobile {
      display: none;
    }
    &--only-mobile {
      @include tablet-max {
        display: none;
      }
    }
  }

  &__children {
    padding: 0 3rem 3rem;
    width: 100%;
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;

    @include mobile {
      padding: 0 2.5rem 2.5rem;
    }
    &--only-mobile {
      @include tablet-max {
        padding: 0 2.5rem 2.5rem;
      }
    }

    &--full-padding {
      padding: 5rem;

      @include mobile {
        padding: 5rem 2.5rem;
      }
      &--only-mobile {
        @include tablet-max {
          padding: 2.5rem;
        }
      }
    }

    &--without-padding {
      padding: 0;
    }

    &--without-scroll {
      overflow: visible;
    }

    &--hide-scroll {
      scrollbar-color: transparent transparent;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__buttons-list {
    padding: 1rem;

    &-item {
      &:not(:last-child) {
        margin-bottom: 0.6rem;
      }
    }

    &-button {
      background-color: $white;
      display: block;
      width: 100%;
      border: 0;
      appearance: none;
      padding: 1.6rem $gutter;
      text-align: center;
      color: #007aff;
      font-size: 2rem;
      transition: $transition;

      &:not(:last-child) {
        border-bottom: 0.1rem solid $lightest-gray;
      }

      &:first-child {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      &:hover,
      &:focus {
        background-color: $lightest-gray;
      }

      &:disabled {
        background-color: $white;
        color: $extra-light-gray;
      }

      &--bold {
        font-weight: 600;
      }
    }
  }
}
