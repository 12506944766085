@import "src/app/styles/mixins/media-queries";

.level-up-page {
  overflow: auto;

  @include tablet-max {
    overflow: initial;
    height: auto;
  }
}
