@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils";

.avatar-upload {
  &__title {
    margin-bottom: 1.5rem;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;
    color: $black;
  }

  &__container-upload {
    @include flex(center, space-around);
    gap: 2rem;
    width: 100%;
    margin-right: 1.8rem;
    padding: 0 3rem;

    &--no-photo {
      @include flex(center, initial, nowrap, column-reverse);
    }
  }

  &__btn-upload {
    padding: 1.5rem 2.4rem;
  }

  &__btn,
  &__delete-btn {
    @include flex(center, space-betweeen, nowrap, column);
    gap: 1rem;
    font-size: 1.4rem;
    color: $black;

    &-circle {
      padding: 1.5rem;
      border-radius: 50%;

      .text-with-icon__icon {
        height: 2rem !important;
        width: 2rem !important;
      }
    }
  }

  &-wrap {
    margin-bottom: 2rem;
  }

  &__img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;

    @include tablet-max {
      min-width: 11rem;
      width: 11rem;
      height: 11rem;
    }
  }

  &__error {
    margin: 1.5rem 0 0;
  }
}
