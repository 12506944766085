@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/utils";

.title-with-content {
  &__title {
    @include flex($align-items: center);

    font-weight: bold;
    color: $black;

    &-text {
      &::first-letter {
        text-transform: uppercase;
      }
    }

    &--small {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 0.8rem;
    }

    &--medium {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 1.5rem;
    }

    &--large {
      font-size: 2rem;
      line-height: 2.7rem;
      margin-bottom: 2.5rem;
    }

    &--can-toggle {
      cursor: pointer;

      &::after {
        @include backgroundImage($background-size: contain);

        margin-left: 1rem;
        content: "";
        display: block;
        min-width: 1rem;
        width: 1rem;
        height: 1rem;
        background-image: url("../../images/icons/icon-trangle-blue.svg");
      }

      &-visible {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    &-btn {
      @include backgroundImage();

      margin-left: 1rem;
      min-width: 2rem;
      width: 2rem;
      height: 2rem;
      background-image: url("../../images/icons/circle-question-gray.svg");
      cursor: help;
    }
  }
}
