@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";

.contacts-form {
  @include flex();

  padding-bottom: 7rem;

  @include tablet-max {
    flex-direction: column-reverse;
  }

  &__form {
    max-width: 41rem;
    width: 100%;

    @include tablet-max {
      margin: 0 0 1rem 0;
      max-width: 100%;
    }
  }

  &__avatar {
    margin-left: 2rem;
    max-width: 41.2rem;
    width: 100%;

    @include tablet-max {
      margin: 0 0 1rem 0;
      max-width: 100%;
    }
  }

  &__btn {
    margin-top: 4rem;
  }
}
