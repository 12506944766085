@import "../../../../app/styles/variables/variables.scss";

.root {
  position: relative;
  display: flex;
  cursor: text;

  &:has(input:disabled) {
    opacity: 0.5;

    .hiddenValue {
      color: $dark-gray;
    }
  }
}

.input:disabled {
  opacity: 1 !important;
}

.hiddenValue {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 24px 12px 9px;
  z-index: 1;
  font-size: 14px;

  span {
    background: $neutral-n-0;
    width: 100%;
    display: block;
    padding: 2px 1px;
  }
}
