@import "src/app/styles/mixins/media-queries.scss";

.base-code-block {
  font-size: 1.4rem;
  line-height: 2rem;
  background: var(--lightest-gray) !important;
  width: 100%;
  font-weight: normal;
  border-radius: 0.6rem;
  padding: 0.6rem 1.2rem !important;
  position: relative;
  z-index: 1;

  &--transparent-bg {
    background: transparent !important;
  }

  &--for-tooltip {
    @include mobile {
      padding: 0.6rem 3.9rem 0.6rem 1.2rem !important;
      text-overflow: ellipsis;
      overflow: hidden !important;
      height: 3.1rem;
      white-space: nowrap;
      display: flex;

      &::before {
        content: "...";
        position: absolute;
        top: 0.4em;
        right: 1.2rem;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: var(--gray);
      }
    }
  }
}
