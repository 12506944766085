@import "src/app/styles/mixins/media-queries";

.popup-grid-group {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__search {
    margin: 0 auto 2rem;
    max-width: 64rem;
    width: 100%;

    @include mobile {
      padding: 0 1.5rem;
    }
  }

  &__container {
    width: 100%;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  &__empty {
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: center;
    color: var(--gray);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
