@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";

.edit-contacts-page {
  &__items {
    margin-bottom: 5rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 118rem;
    list-style: none;

    @include desktop-max {
      grid-template-columns: 1fr;
    }
  }

  &__add {
    text-decoration: none;
    display: inline-block;
  }
}
