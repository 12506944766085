@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils.scss";

.rating-page {
  max-width: 70rem;

  &__info {
    margin-bottom: 3rem;
    max-width: 63.7rem;
    width: 100%;
  }

  &__list {
    margin-top: 3rem;
    font-size: 1.6rem;
    line-height: (21.79/16);

    &-item {
      @include flex(flex-start);

      @include tablet-max {
        font-size: 1.4rem;
        line-height: (19.07/14);
      }

      &:not(:last-child) {
        margin-bottom: 1.1rem;
      }

      &:last-child {
        margin-bottom: -0.4rem;
      }

      &--full-width {
        display: block;
      }

      &-title {
        font-weight: normal;
        color: $gray;
        white-space: nowrap;
        margin-bottom: 0.4rem;
      }

      &-description {
        @include flex(flex-start);

        font-weight: normal;
        color: $black;
        flex-grow: 1;
        align-self: flex-end;
        margin-bottom: 0.4rem;
        text-wrap: wrap;

        &::before {
          content: "";
          border-bottom: 0.2rem $extra-light-purple dotted;
          flex-grow: 1;
          min-width: 1.2rem;
          height: 1.6rem;
        }

        &--full-width {
          display: block;
          width: 100%;
        }
      }

      &-link {
        color: $dark-blue;
        text-decoration: none;
        word-break: break-word;
      }
    }
  }
}
