@import "../../../app/styles/mixins/grid.scss";
@import "../../../app/styles/mixins/media-queries.scss";
@import "../../../app/styles/mixins/utils.scss";

.header {
  display: none;
  position: relative;
  border-radius: 0 0 6px 6px;
  @include tablet-max {
    @include flex($align-items: center, $justify-content: space-between);

    padding: 0 1.2rem;
    height: $header-height;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background: $dark-blue;
    z-index: 1000;
    transition: 0.3s;

    &--hide {
      transform: translateY(-100%);
    }

    &__menu {
      margin-right: 0.7rem;
    }

    &__logo {
      @include backgroundImage($background-size: contain);

      background-image: url("../../images/aside-bar-logo.svg");
      min-width: 10.6rem;
      width: 10.6rem;
      height: 2.2rem;
      display: block;
    }

    &__wrap {
      @include flex($align-items: center);
      column-gap: 0.7rem;
      height: 100%;
    }

    &__profile {
      @include flex($align-items: center, $justify-content: center);

      width: 4.6rem;
      height: 80%;
      position: relative;
      &-img {
        @include backgroundImage();

        min-width: 3.4rem;
        width: 3.4rem;
        height: 3.4rem;
        border-radius: 100%;
        display: block;
        transition: 0.2s linear;

        &--active-menu {
          transform: translateY(-200%);
        }
      }

      &--active::before {
        content: "";
        height: 0.3rem;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $white;
        transition: opacity 0.2s linear 0.15s, transform 0.4s linear;
      }

      &--active-menu {
        pointer-events: none;
      }

      &--active-menu::before {
        transition: opacity 0.25s linear, transform 0.4s linear;
        opacity: 0;
        transform: translateX(70vw);
      }
      &:active {
        border-radius: 1000px;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    &__btn {
      @include flex(
        $flex-direction: column,
        $align-items: flex-end,
        $justify-content: space-between
      );

      &:active {
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 1000px;
      }

      //margin-left: 1.5rem;
      background: transparent;
      border: 0;
      padding: 1.1rem 1rem;
      height: 60%;

      &::before,
      &::after {
        width: 1.6rem;
        content: "";
        transition: 0.3s linear;
      }

      &::before {
        transform-origin: right bottom;
      }

      &::after {
        transform-origin: right top;
      }

      &-item,
      &::before,
      &::after {
        height: 0.2rem;
        background: $white;
        border-radius: 1rem;
      }

      &-item {
        width: 1.3rem;
        transition: 0.1s linear 0.2s;

        &--active-menu {
          transition: 0.1s linear;
          width: 0;
        }
      }

      &--active-menu::before {
        transform: rotate(-0.125turn);
      }

      &--active-menu::after {
        transform: rotate(0.125turn);
      }
    }
  }
  &__navigation {
    position: absolute;
    background-color: $dark-blue;
    left: 0;
    bottom: 0;
    width: 100%;
    transform: translate(0, 100%);
    animation: fade-out-modal 0.2s ease-in-out;
    border-radius: 0 0 6px 6px;
    &_is_closed {
      animation: fade-in-modal 0.2s ease-in-out;
    }
  }
  &__is_dropdown {
    border-radius: 0;
  }
}

@keyframes fade-out-modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in-modal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
