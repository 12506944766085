@import "src/app/styles/mixins/media-queries";

.lvl-up-block {
  display: flex;
  align-items: flex-start;
  gap: 2.4rem;
  border-radius: 2rem;
  box-shadow: 0 0 2rem 0 rgba(40, 58, 151, 0.2);
  width: 100%;
  height: 100%;
  padding: 2.8rem;

  @include tablet-max {
    flex-direction: column;
    padding: 2.4rem;
  }

  &__img {
    width: 100%;
    height: 100%;
    max-width: 122px;
    max-height: 122px;
    align-self: center;
    @include tablet-max {
      display: none;
    }
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 3rem;
    color: $black;
    margin-bottom: 1rem;

    @include tablet-max {
      margin-bottom: 0.8rem;
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }

  &__titles {
    margin-left: 1.6rem;

    @include tablet-max {
      margin-left: 0;
    }
  }

  &__subtitle {
    font-size: 1.6rem;
    font-weight: 400;
    color: $neutral-n-20;
  }

  &__slogan {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  &__btn {
    padding: 1.2rem 2.4rem;
    font-size: 1.4rem;
    height: 4.2rem;
    margin-top: auto;
    align-self: flex-end;

    @include tablet-max {
      margin-top: 2rem;
      width: 100%;
    }

    &-link {
      white-space: nowrap;
    }
  }
}
