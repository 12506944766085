@import "src/app/styles/mixins/grid.scss";

.base-pagination {
  @include flex($flex-direction: column, $align-items: center);

  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;

  @include tablet {
    @include flex($justify-content: space-between, $align-items: center);
  }

  @include desktop {
    font-size: 1.6rem;
  }

  &__page-size {
    @include flex($align-items: center);

    @include laptop {
      margin-right: auto;
    }

    &-label {
      display: block;
      margin-right: 1.4rem;
      line-height: (19/14);
      color: $black;
    }

    &-select {
      min-width: 6rem;

      @include laptop {
        margin-right: auto;
      }
    }
  }

  &__nav {
    order: -1;
    margin-bottom: 1.5rem;

    @include tablet {
      order: initial;
      margin-bottom: initial;
    }
  }

  &__items {
    @include flex($align-items: center);

    gap: 1.2rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    color: inherit;
    display: block;
    border-radius: $border-radius-small;
    font-size: 1.4rem;
    padding: 0.8rem 1.2rem;

    &--active {
      background-color: $dark-blue;
      color: $white;
      font-weight: bold;
      cursor: default;
    }

    &--empty {
      padding: 0.4rem;
      color: $extra-light-gray;
    }

    &--has-arrow {
      color: $dark-blue;
      font-size: 0;
      padding: 0;

      @include tablet {
        font-size: inherit;
      }
    }
  }

  &__link {
    @include flex($align-items: center, $justify-content: center);

    color: inherit;
    text-decoration: none;
    text-align: center;
  }

  &__arrow {
    &--prev {
      transform: rotate(90deg);
    }

    &--next {
      transform: rotate(-90deg);
    }
  }
}
