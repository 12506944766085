@import "../../../../app/styles/variables/variables.scss";
@import "../../../../app/styles/mixins/grid.scss";

.chart {
  @include flex(flex-end, space-between);
  gap: 4px;
  height: 248px;
  padding-top: 16px;
  border-left: 1px solid $neutral-n-300;
  border-bottom: 1px solid $neutral-n-300;

  &_bar {
    width: 20px;
    background: $blue-b-100;
  }

  &_wrapper {
    margin-top: 24px;
    display: grid;
    grid-template-columns: minmax(50px, 70px) 1fr;
    gap: 8px;
    color: $neutral-n-300;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  }
}

.y_labels {
  @include flex(flex-end, space-between, nowrap, column);
  padding-top: 4px;
}

.x_labels {
  @include flex(flex-start, space-between);
  gap: 4px;

  > * {
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-orientation: sideways-right;
  }
}

.tooltip {
  padding: 12px;
  border-radius: 8px !important;
  font-size: 12px;
  color: $neutral-n-50 !important;
  max-width: max-content;
  display: flex;
  justify-content: space-between;
  gap: 32px;

  span {
    color: $neutral-n-0;
  }
}
