@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/mixins/utils.scss";

.formulas-block {
  display: flex;
  flex-direction: column;
  grid-row: 6/-1;
  grid-column: 1/2;
  gap: 2rem;

  @include desktop-max {
    gap: 1rem;
    order: 4;
  }

  &-type {
    gap: 2rem;

    @include mobile {
      gap: 1rem;
    }

    &--calc-type {
      &_availability {
        order: 1;
      }

      &_role {
        order: 2;
      }

      &_reward {
        order: 3;
      }

      &_dislike {
        order: 4;
      }
    }

    &__title {
      margin-bottom: 2rem;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color-26;
      display: flex;
      align-items: center;
    }

    &__question-btn {
      @include backgroundImage($background-size: contain);

      margin-left: 0.8rem;
      min-width: 2rem;
      width: 2rem;
      height: 2rem;
      background-image: url("../../../../../shared/images/icons/circle-question-gray.svg");
      border: 0;
      background-color: transparent;
      cursor: help;
    }

    &__test-field {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }

      &--tenacity {
        &:nth-child(odd):not(:last-child) {
          padding-bottom: 3rem;
          border-bottom: 0.1rem solid var(--extra-light-gray);
        }
      }
    }
  }
}
