@import "src/app/styles/mixins/grid";

h2 {
  font-size: 2rem;
  font-weight: 700;
}

.faqBody {
  margin-top: 3rem;
}

.box {
  border-radius: $border-radius-large;
  box-shadow: 0 0 2rem 0 #283a9733;
  padding: 4rem;

  @include mobile-portrait {
    padding: 2.4rem;
  }
}

.policies {
  @include flex(unset, unset, nowrap, column);
  margin-top: 4rem;
  gap: 2rem;
}

.policyBlock {
  padding: 2.8rem;

  @include mobile-portrait {
    padding: 2.4rem;
  }
}

.policy {
  @include flex(center, space-between);
  gap: 2rem;

  @include mobile-medium-small {
    flex-direction: column;
  }
}

.policyButton {
  @include mobile-medium-small {
    width: 100%;
  }
}
