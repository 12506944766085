@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/media-queries";

.test-formula-popup {
  min-width: 60rem;
  width: 100%;

  @include tablet-max {
    min-width: 100%;
  }

  &__item {
    @include flex($flex-wrap: wrap);

    margin-bottom: 1rem;
    margin-right: 1rem;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: $gray;

    &-description {
      margin-left: 1rem;
      font-weight: bold;
      color: $black;
    }
  }

  &__list {
    counter-reset: section;

    &-title {
      margin-top: 2rem;
      margin-bottom: 0.7rem;
      font-size: 1.6rem;
      line-height: 1.8rem;
      font-weight: bold;
      color: $black;
    }

    &-item {
      @include flex($flex-wrap: wrap);

      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: bold;
      color: $black;

      &::before {
        margin-right: 1rem;
        counter-increment: section;
        content: counter(section) ".";
      }
    }
  }
}
