.dislike-info {
  width: 19rem;

  &--large {
    width: 27.6rem;
  }

  &__result {
    margin-bottom: 1.6rem;
  }
}
