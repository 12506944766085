@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";


.base-popup__children {
  .base-select {
    &__menu {
      top: 0;
      position: relative;
    }
  }
}

.user-profile {
  &__sessions {
    margin-right: auto;
  }
  &__status-block {
    margin-top: 3rem;
    display: flex;
    gap: 2rem;

    @include mobile {
      flex-wrap: wrap;
    }

    &__select {
      min-width: 10rem;
      max-width: 35rem;

      @include mobile {
        min-width: 100%;
      }
    }
  }

  &__header {
    display: flex;
    margin-bottom: 2rem;
    width: 100%;
    justify-content: space-between;

    @include mobile {
      flex-wrap: wrap;
      gap: 2rem;
    }


    a {
      white-space: nowrap;
      margin-right: 1.2rem;

      @include mobile {
        width: 100%;
      }
    }

    button {
      padding: 0;
    }
  }
}
