@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/variables/variables";

.news-card {
  color: $black;

  &__title {
    font-size: $font-size-4;
    font-weight: 700;
    line-height: 2.5rem;

    @include mobile() {
      font-size: $font-size-2;
      line-height: 1.9rem;
    }
  }

  &__description {
    font-size: $font-size-3;
    font-weight: 400;
    line-height: 2.2rem;
    margin-top: 0.6rem;

    @include mobile() {
      font-size: $font-size-1;
      line-height: 1.6rem;
    }

    &__link {
      color: $dark-blue;
    }
  }

  &__link {
    display: block;
    width: fit-content;
    margin-top: 1rem;
    font-size: $font-size-3;
    font-weight: 600;
    line-height: 2.2rem;
    color: $dark-blue;
    text-decoration: none;

    &:active,
    &:focus,
    &:visited {
      color: inherit;
    }

    @include mobile() {
      font-size: $font-size-1;
      line-height: 1.6rem;
    }
  }
}
