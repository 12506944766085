@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/variables/variables";

.container_title {
  width: 50rem;
  text-align: center;
  margin-bottom: 3.2rem;

  @include mobile {
    width: auto;
  }
  &__text {
    font-size: 1.4rem;
    color: $gray;
    font-weight: 400;
  }
}

