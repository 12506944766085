@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils";

.form-control {
  &--margin-bottom {

    &_smallest {
      margin-bottom: 1.2rem;
    }

    &_small {
      margin-bottom: 2.6rem;
    }

    &_medium {
      margin-bottom: 3.6rem;
    }

    &_large {
      margin-bottom: 4.6rem;
    }

    &_default {
      margin-bottom: 2rem;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    margin-bottom: 1rem;

    &-size {
      &--medium {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }

      &--small {
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }

    &-color {
      &--black {
        color: $color-26;
      }

      &--gray {
        color: $gray;
      }

      &--white {
        color: $white;
      }
    }
  }

  &__field {
    @include flex($justify-content: space-between, $align-items: center);

    position: relative;

    &-input {
      width: 100%;
    }

    &-refresh-btn {
      margin-left: 1rem;
      min-width: 3rem;
      width: 3rem;
      height: 3rem;
      border: 0;
      outline: 0;
      background: transparent;
      display: block;

      &::before {
        @include backgroundImage($background-size: 90%);

        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-image: url("../../../shared/images/icons/circle-arrow-blue.svg");
        cursor: pointer;
      }

      &--is-desktop-outside {
        height: auto;
        position: absolute;
        right: -5rem;
        top: 0;
        bottom: 0;

        @include tablet-max {
          position: static;
          height: 3rem;
        }
      }

      &--loading {
        &::before {
          animation: refreshSpine 0.7s infinite;

          @keyframes refreshSpine {
            from {
              transform: rotate(0deg);
            }

            to {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
}
