@import "src/app/styles/mixins/grid";

.wrapper {
  display: flex;
  flex-direction: row;
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  padding: 0.8rem 2rem 0.8rem 0.8rem;
  align-items: center;
  border-radius: 1.2rem;
  background: $neutral-n-30;
  gap: 8px;
  font-weight: 600;
  font-size: 1.6rem;
}

.buttonCross {
  @include flex(center, center);
  margin-left: auto;
}