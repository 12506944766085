.profile-relative-table {
  margin: 2rem 0 2rem 0;


  .data-grid {

    &__th {
    width: 396px;
    }

    &__table-wrapper {
      border-radius: 2rem;
      box-shadow: var(--shadow-for-carts);
    }
  }

  button {
    height: 3.4rem;
    padding: 0 1.6rem;
    font-weight: 600;
  }
}