@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/mixins/utils";

.welcome-popup {
  flex-direction: column;
  width: 44rem;

  @include mobile {
    width: 100%;
  }

  &__wrapper {
    padding: 2rem 4rem 4rem 4rem;

    @include mobile {
      padding: 2rem;
    }
  }

  &__banner {
    @include backgroundImage();

    height: 18.3rem;
    background-image: url("./../../../../shared/images/welcome-popup-back.svg");
  }

  &__title {
    width: 26rem;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.5rem;
    text-align: center;
    margin: 0 auto 2rem;
  }

  &__subtitle {
    margin-bottom: 2rem;
  }

  &__content {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    color: $dark-gray;
  }

  &__item {
    margin-bottom: 2rem;

    &-label {
      font-weight: 700;
    }
  }

  &__btn {
    height: 5.6rem;
    margin-top: 4rem;
  }
}
