@import "src/app/styles/mixins/media-queries";

.box {
  padding: 2.4rem;
  border-radius: 1rem;
  background-color: $white;
  position: relative;
  box-shadow: 0 0 2rem rgba(100, 163, 198, 0.1);

  &[aria-disabled="true"] {
    opacity: 0.5;
    pointer-events: none;
  }

  @include mobile {
    padding: 1.5rem;
    box-shadow: 0 0 1rem rgba(100, 163, 198, 0.1);
  }

  &--no-padding {
    padding: 0;
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--no-round {
    border-radius: 0;
  }
}
