@mixin set-font($family: null, $size: null, $lineHeight: null, $weight: null) {
  @if ($family) {
    font-family: $family, sans-serif;
  }
  @if ($size) {
    font-size: $size;
  }
  @if ($lineHeight) {
    line-height: $lineHeight;
  }
  @if ($weight) {
    font-weight: $weight;
  }
}
