@import "src/app/styles/mixins/media-queries";

.fines-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 10rem);

  .data-grid {
    width: 100%;
  }

  &-without-fines {
    display: flex;
    align-items: center;
    color: $gray;
    text-align: center;
    font-weight: 400;
    justify-content: center;
    flex-direction: column;
    font-size: 24px;

    button {
      margin-top: 2.4rem;
      min-width: 32rem;
      line-height: 3.2rem;
      padding: 1.2rem 2rem;
    }
  }
}

.fines-control-panel-buttons {
  display: flex;
  flex-direction: row;

  button:not(:last-child) {
    margin-right: 1.6rem;
  }
}
