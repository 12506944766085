@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/mixins/utils";

.email-newsletter-preview {
  flex-direction: column;
  width: 82rem;

  @include mobile {
    width: 100%;
  }

  &__corner-gradient {
    height: 24rem;
    background: linear-gradient(215.39deg, #283a97 0.76%, #1c3e88 1%);
    position: relative;
  }

  &__container {
    margin-left: 3.5rem;
    width: 42rem;
    position: relative;
    z-index: 1;
    padding: 3rem 0;
  }

  &__background {
    width: 37rem;
    height: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;

    &-image {
      width: 100%;
    }
  }


  &__logo {
    width: 16rem;
    min-height: 3.5rem;
    margin-bottom: 2rem;
    background-image: url("../../../../../src/shared/images/aside-bar-logo.svg");
  }

  &__header {
    color: white;
    font-size: 3.4rem;
    font-weight: bold;
    padding: 1rem 0;
  }

  &__description {
    color: white;
    font-size: 1.8rem;
  }

  &__theme {
    display: flex;
    font-size: 2.6rem;
    font-weight: bold;
    justify-content: center;
    padding-top: 5rem;
  }

  &__email-content {
    font-size: 1.8rem;
    text-align: justify;
    padding: 2rem 5rem 4rem;
    line-height: 2.5rem;
    height: auto;
  }

  &__contacts {
    background-color: $color-22;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 14rem 5rem;
    text-align: center;
  }
}

