@import "src/app/styles/variables/variables";
@import "src/app/styles/mixins/grid";

.visualArea {
  background: $neutral-n-10;
  color: $neutral-n-100;
  border-radius: $border-radius-small;
  padding: .8rem 1.2rem;
  height: 3.4rem;

  @include flex(center, flex-start);
  gap: .8rem;

  input {
    background: none;
    border: none;
    color: $neutral-n-100;
    outline: none;
    font-size: $font-size-2;
    width: 4.5rem;

    &:focus {
      color: $neutral-n-300;
    }

    &::placeholder {
      opacity: 1;
      color: $neutral-n-100;
    }
  }

  &.expanded input {
    max-width: 24rem;
    width: 100%;
  }

  &.active {
    border: 1px solid $neutral-n-50;
    background: $neutral-n-0;
  }

  .cross {
    cursor: pointer;
  }
}