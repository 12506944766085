@import "src/app/styles/mixins/media-queries";

.export-history-page {

  &-data-grid-wrapper {
    height: calc(100vh - 10rem);

    @include mobile-landscape {
      height: initial;
    }

    @include tablet-landscape {
      height: initial;
    }
  }


  .base-panel {
    padding: 0;
  }

  .control-panel {
    padding: 0;
  }

  .page-wrapper__container {
    padding: 2.4rem 3rem 0 3rem;
  }

  &--included-fields {
    max-width: 25rem;
    white-space: nowrap;
    position: absolute;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    overflow: hidden;

    @include tablet-max {
      max-width: 16rem;
    }

    .base-tooltip {
      max-width: 31rem;
      position: relative !important;
      white-space: normal;
      text-align: justify;
    }
  }
}