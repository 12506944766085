@import "src/app/styles/mixins/grid.scss";

.users-archive-datagrid-wrapper {
  height: calc(100vh - 26.1rem);

  @include mobile-landscape {
    height: initial;
  }

  @include tablet-landscape {
    height: initial;
  }

  @include laptop {
    height: calc(100vh - 29rem);
  }
}
