@import "src/app/styles/mixins/media-queries";

.social-networks-block {
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include tablet-max {
    padding: 1.5rem;
  }

  @include mobile {
    display: block;
  }

  &__title {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.7rem;
    color: var(--black);

    @include desktop-max {
      margin-right: 1rem;
    }

    @include mobile {
      margin: 0 0 2rem;
    }
  }

  &__controls {
    display: flex;
    gap: 1.4rem;

    @include mobile {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }

    @include mobile-small {
      gap: 1rem;
    }

    &-item {
      padding: 1.2rem;
      background: $lightest-gray;
      text-decoration: none;
      border-radius: $border-radius-small;

      @include mobile-small {
        padding: 1rem;
      }

      &--globe-gray {
        padding: 1.1rem 1.6rem;
        grid-column: 1 / span 5;

        @include mobile-small {
          padding: 0.9rem;
        }
      }
    }
  }
}
