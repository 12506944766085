@import "src/app/styles/variables/variables";

.fine-for-inaction-popup {
  .base-popup__children {
    padding: 0 4rem 4rem;
  }
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 36rem;

    &__description {
      font-size: 1.4rem;
      color: $gray;
      text-align: center;
      margin-bottom: 2.4rem;
    }

    button {
      margin-top: 4rem;
      width: 100%;
      height: 5.6rem;
      padding: 1.2rem 2rem;
    }
  }
}
