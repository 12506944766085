@import "../variables/variables";

@mixin competenceColors($style: color, $opacity: 100) {
  &--communication {
    #{$style}: rgba($blue, $opacity);
  }

  &--analytics {
    #{$style}: rgba($purple, $opacity);
  }

  &--command {
    #{$style}: rgba($sea-wave, $opacity);
  }

  &--thinking3d {
    #{$style}: rgba($pale-orange, $opacity);
  }

  &--economics {
    #{$style}: rgba($pink, $opacity);
  }

  &--jurisprudence {
    #{$style}: rgba($bright-green, $opacity);
  }

  &--microelectronics {
    #{$style}: rgba($teal, $opacity);
  }
}