@import "../../../app/styles/mixins/grid.scss";
@import "../../../app/styles/mixins/media-queries.scss";
@import "../../../app/styles/mixins/utils.scss";

@mixin text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.footer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  bottom: 0;
  transform: translate(0, 86%);
  width: 100%;
  padding: 4rem 2.4rem;
  border-radius: 6px 6px 0 0;
  background-color: $dark-blue;
  color: $white;
  transition: transform 0.3s ease-in-out;
  z-index: 5;
  @include tablet-max {
    transform: translate(0, 96%);
  }
  &_full {
    transform: translate(0, 0);
  }
  &_static {
    position: static;
    transform: inherit;
  }
  @include tablet-max {
    padding: 4rem 1rem;
  }
  @include mobile-portrait {
    flex-direction: column;
  }

  @include mobile {
    align-items: center;
  }
  @include mobile-medium-small {
    align-items: flex-start;
    padding: 2.4rem 2.4rem;
  }

  &__top {
    display: flex;
    align-items: flex-start;
    max-width: 118.8rem;
    width: 100%;

    @include mobile {
      align-items: center;
      flex-direction: column;
    }
    @include mobile-medium-small {
      align-items: flex-start;
    }
    &_card {
      display: flex;
      flex-direction: column;
      row-gap: 1.4rem;
      max-width: 28rem;
      @include mobile {
        max-width: 41rem;
      }

      &:first-child {
        @include tablet {
          max-width: 28rem;
        }
      }

      &:not(:last-child) {
        margin-right: auto;
        @include mobile {
          margin: 0 0 3rem 0;
        }
      }
    }
    &_heading {
      padding-bottom: 1.2rem;
      border-bottom: 1px $extra-light-gray solid;
    }
    &_link {
      display: flex;
      align-items: center;
      color: $white;
      text-decoration: none;
      @include text;
      & > div {
        display: inline-block;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          bottom: -1%;
          left: 0;
          background: $white;
          width: 0;
          height: 0.1rem;
          transition: width 0.2s cubic-bezier(0.19, 0.56, 0.68, 0.61);
        }
        &:hover {
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
  &__text {
    @include text;
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2rem;
    margin-top: 3rem;
  }
  &__icon {
    margin-right: 1rem;
    width: 1.9rem;
    height: 1.9rem;
  }
  &__icon_btn {
    padding: 0;
    border: 0;
    & > span > svg {
      opacity: 1;
      transition-property: transform, opacity;
      transition-duration: 0.2s;
      transition-timing-function: ease;

      &:hover {
        transform: scale(1.1);
        opacity: 0.8;
      }
    }
  }
  &__chevron {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid $dark-blue;
    background: white;
    cursor: pointer;
    transition: background 0.2s ease;
    @include tablet-max {
      display: none;
    }
    &:hover {
      background: $extra-light-purple;
    }
    &:active {
      background: $extra-light-gray;
    }
    &_icon_down {
      animation: spin-down 0.5s normal forwards ease-in-out;
    }
    &_icon_up {
      animation: spin-up 0.5s normal forwards ease-in-out;
    }
    &_icon > g > path {
      fill: $dark-blue;
    }
  }

  &__group_links {
    @include flex;
    gap: 1.7rem;

    @include mobile {
      flex-direction: column;
      gap: 1.2rem;
    }
  }
  &__support__hours {
    @include text;
    font-size: 12px;
  }
}

.heading {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.footer_observable {
  position: absolute;
  width: 0;
  height: 0;
  bottom: 100px !important;
}

@keyframes spin-up {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes spin-down {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
