@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/variables/variables";

.base-card-wrapper {
  margin: 0 4rem;
  padding: 2rem 0;
  box-sizing: border-box;
  box-shadow: inset 0px -0.5px 0px #e0e4f8;
  position: relative;

  @include mobile() {
    margin: 0 1.5rem;
    padding: 1.5rem 0;
  }

  &:last-child {
    box-shadow: none;
  }

  &__title {
    font-size: $font-size-4;
    font-weight: 700;
    line-height: 2.5rem;
    color: $black;

    @include mobile() {
      font-size: $font-size-2;
      line-height: 1.9rem;
    }
  }

  &__content {
    margin-top: 1.6rem;

    @include mobile() {
      margin-top: 1.8rem;
    }
  }
}

.card-content {
  display: flex;
  gap: 2rem;
  @include mobile() {
    gap: 1.5rem;
  }

  &__image {
    border-radius: 0.6rem;
    $size: 7rem;
    height: $size;
    width: $size;
    flex-shrink: 0;

    @include mobile() {
      $size: 5.8rem;
      height: $size;
      width: $size;
    }
  }

  &__right-side {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.base-card-when-date {
  font-size: $font-size-3;
  font-weight: 400;
  line-height: 2.2rem;
  color: $gray;
  margin-top: 1.4rem;

  @include mobile() {
    font-size: $font-size-1;
    line-height: 1.6rem;
  }
}
