@import "src/app/styles/mixins/media-queries";

.assessment-additional-parameters-popup {
  width: 63rem;
  min-height: 12rem;
  display: grid;
  gap: 0 1rem;
  grid-template-columns: 1fr 1fr;

  @include mobile {
    width: 100%;
    grid-template-columns: 1fr;
  }

  &__item {
    margin-bottom: 3.5rem;

    &--string {
      order: 1;
    }

    &--number,
    &--integer {
      order: 2;
    }

    &--boolean {
      margin-bottom: 2rem;
      order: 3;
    }
  }
}
