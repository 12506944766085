.relativesBlock {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.titleText {
  font-size: 2.8rem;
  font-weight: 700;
}
.relativeCount {
  font-size: 1.6rem;
  font-weight: 400;
  margin-left: 0.8rem;
  color: var(--gray)
}

.relativesContent {
  display: flex;
  padding: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 2rem 0 2rem 0;
  border-radius: 2rem;
  box-shadow: var(--shadow-for-carts);
  background-color: var(--white);
}

.withoutRelatives {
  font-size: 2rem;
  font-weight: 600;
  color: var(--gray)
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5.6rem;
  max-width: 34rem;
  margin-bottom: 4rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}