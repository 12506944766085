@import "../../../../app/styles/mixins/media-queries";
@import "../../../../app/styles/mixins/grid";

.confirm-popup {
  padding: 4rem;
  width: 44rem;
  background: var(--white);
  border-radius: $border-radius-large;

  @include mobile {
    max-width: 100%;
  }

  &__title {
    font-weight: 700;
    font-size: $font-size-5;
    text-align: center;
    color: var(--black);
  }

  &__description {
    margin-top: 1.2rem;
    font-weight: 400;
    font-size: 1.4rem;
    text-align: center;
    color: var(--black);
  }

  &__message {
    margin-top: 3rem;
  }

  &__controls {
    margin-top: 4rem;;
    @include flex(center, initial);
    gap: 2rem;
  }
}
