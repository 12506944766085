@import "src/app/styles/mixins/media-queries.scss";

.avatar-crop-popup {
  padding: 0 7rem;
  max-width: 50rem;

  @include mobile {
    padding: 0;
    max-width: 100%;
  }

  &__descriptions {
    list-style: none;

    &-item {
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 2.2rem;
      text-align: center;
      color: $dark-gray;

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }

      @include mobile {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }

  &__crop {
    margin: 3.5rem 0;
    width: 100%;

    @include mobile {
      margin: 3rem 0;
    }

    .ReactCrop__crop-selection {
      border: 0;
      box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.7);

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 0.1rem solid $gray;
      }
    }

    .ReactCrop__drag-handle:after {
      min-width: 1.2rem;
      width: 1.2rem;
      height: 1.2rem;
      background: $gray;
      opacity: 0.7;
    }

    .ReactCrop__image {
      width: 100%;
    }
  }
}
