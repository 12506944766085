@import "src/app/styles/mixins/grid";

@import "src/app/styles/mixins/media-queries";

@import "src/app/styles/mixins/utils";

.level-up-card {
  width: 100%;
  margin-right: 2rem;
  background-color: $white;
  padding: 4rem;
  border-radius: $border-radius-large;
  box-shadow: 0 0 2rem 0 rgba(40, 58, 151, 0.2);

  @include mobile {
    padding: 2rem;
  }

  &--disabled {
    filter: opacity(0.6);
    cursor: not-allowed;
  }

  &__info {
    @include flex();
    justify-content: center;

    margin-bottom: 2.4rem;

    &-image {
      @include backgroundImage();

      width: 6rem;
      height: 6rem;
      border-radius: 2rem;
      margin-bottom: 0.8rem;
    }

    &-title {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.7rem;
      text-transform: uppercase;

      @include mobile {
        font-size: 1.8rem;
        line-height: 2.5rem;
      }
    }

    &-rules {
      position: absolute;
      top: 4rem;
      right: 4rem;

      @include mobile {
        top: 2rem;
        right: 2rem;
      }
    }

    &-heading {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__list {
    max-height: 52rem;
    overflow-y: auto;
    &-item {
      &:not(:last-child) {
        margin-bottom: 1.2rem;
      }
    }
  }
}
