@import "src/app/styles/mixins/grid.scss";

.resume-upload {
  @include flex($flex-direction: column);

  &__title {
    margin-bottom: 1.5rem;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;
    color: $black;
  }

  &__description {
    margin-bottom: 1.5rem;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $dark-gray;

    &-item {
      &--uppercase {
        text-transform: uppercase;
      }

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }
  }

  &__preview {
    background: $lightest-gray;
    padding: 2rem;
    border-radius: $border-radius-small;
    color: $black;
    font-size: 1.4rem;
    width: 100%;

    @include flex(center, center, nowrap, column);
    gap: 1em;

    &-img {
      border-radius: $border-radius-small;
      box-shadow: 0 0 2rem 0 rgba($dark-blue, 13%);
      max-width: 23rem;
      max-height: 16rem;
    }

    &-filename {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 20rem;
    }
  }

  &__upload {
    cursor: pointer;
    background: $dark-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 700;
    color: white;
    width: 100%;
    padding: 1.6rem 2.4rem;
    border-radius: $border-radius-small;

    &:has(input:disabled) {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
    border-radius: $border-radius-small;
    box-shadow: 0 0 2rem 0 rgba($dark-blue, 13%);
    max-width: 23rem;
    max-height: 16rem;
  }

  &__file {
    margin-top: 2rem;
    position: relative;
  }
}
