@import "src/app/styles/variables/variables";

.Skeleton {
    width: 100%;
    height: 50px;
    position: relative;
    box-shadow: 0 0 2rem 0 rgba(40, 58, 151, 0.2);
    overflow: hidden;

    &::before {
        content: "";
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 80%;
        background: linear-gradient(to right, transparent 0%, $lightest-gray 50%, transparent 100%);
        animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
}

@keyframes load {
    from {
        left: -150px;
    }

    to {
        left: 100%;
    }
}
