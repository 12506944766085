@import "src/app/styles/mixins/grid.scss";

.accordion {
  width: 100%;
  padding-bottom: 1.6rem;

  &:not(:last-child) {
    border-bottom: 1px solid $extra-light-gray;
  }

  &:not(:first-child) {
    margin-top: 1.6rem;
  }
}

.title {
  @include flex(center, space-between);
  gap: 2rem;
  cursor: pointer;

  h3 {
    font-size: 1.6rem;
    font-weight: 700;
  }

  img {
    transform: rotate(-45deg);
    transition: $transition;
    user-select: none;
    height: 1.3rem;
    width: 1.3rem;

    &.active {
      transform: rotate(0);
      animation: cross-click 0.5s ease-out;
    }
  }
}

@keyframes cross-click {
  from {
    transform: scale(1) rotate(-45deg);
  }
  15% {
    transform: scale(0.8) rotate(calc(-45deg * 0.15));
  }
  to {
    transform: scale(1) rotate(0);
  }
}

.body {
  display: grid;
  grid-template-rows: 0fr;
  transition: $transition;

  &.active {
    grid-template-rows: 1fr;

    & > div {
      padding: 1.6rem 6.4rem 0 0;
    }
  }

  & > div {
    overflow: hidden;
    font-size: 1.6rem;
    font-weight: normal;
    padding-right: 6.4rem;
    transition: $transition;

    & > * {
      all: revert !important;
      margin: 0 !important;
    }
  }
}
