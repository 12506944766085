@import "src/app/styles/variables/variables.scss";
@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils";
@import "src/app/styles/mixins/competence-colors.scss";

.lvl-rating {
  padding: 2.8rem;
  width: 39.6rem;
  height: 100%;

  @include tablet-max {
    padding: 2.4rem;
    width: 100%;
  }
  &__title {
    margin-bottom: 1.6rem;
    display: flex;
    justify-content: flex-start;
    font-weight: 700;
    flex-direction: column;
    font-size: 2.4rem;
    line-height: normal;
    color: var(--black);

    @include tablet-max {
      font-size: 2rem;
    }

    &-text-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;

      &--main {
        margin-bottom: 1.6rem;
      }
    }
  }

  &__sub-title {
    font-size: 1.6rem;
    line-height: 1.4rem;
    font-weight: 600;
    margin-left: 0.4rem;
    color: var(--gray);

    @include tablet-max {
      font-size: 1.4rem;
      line-height: 2.1rem;
    }
  }

  &__places {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 3.2rem;
    color: var(--gray);
    display: flex;
    align-items: center;

    &--additional {
      font-size: 1.8rem;
    }

    @include tablet-max {
      line-height: 1.6rem;
    }

    &-icon {
      margin-right: 0.8rem;
    }

    &-divider {
      margin: 0 0.4rem;
      color: var(--gray);
    }
  }

  &__rate {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.6rem;

    @include tablet-max {
      margin-bottom: 1.4rem;
    }

    &-large {
      @include competenceColors(color);

      font-weight: 700;
      font-size: 4rem;
      line-height: 3.2rem;
      color: $dark-blue;

      @include tablet-max {
        font-size: 2.8rem;
      }
    }

    &-small {
      font-weight: 600;
      font-size: 3rem;
      line-height: 2.4rem;
      color: var(--gray);

      @include tablet-max {
        font-size: 2rem;
      }
    }

    &-diff {
      margin-left: 1.4rem;
      margin-bottom: 0.2rem;
      padding: 0.4rem 1.2rem;
      border-radius: var(--border-radius-small);
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.7rem;

      @include tablet-max {
        margin-left: 0.9rem;
        margin-bottom: 0.5rem;
        padding: 0.4rem 0.6rem;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }

      &--increase {
        background: $light-green;
        color: $green;
      }

      &--decrease {
        background: $light-red;
        color: $red;
      }
    }

    &-link {
      text-decoration: none;

      button {
        @include tablet-max {
          padding: 1rem 1.5rem;
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
    }

    &--level-wrapper {
      display: flex;
      align-items: flex-end;
    }
  }

  &--additional-competence {
    border-radius: 2rem;
    background: var(--white);
    padding: 2.4rem;
    width: 36rem;
    height: 21.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet-max {
      width: 32.7rem;
    }

    box-shadow: 0 0 20px 0 rgba(40, 58, 151, 0.2);

    .lvl-rating {
      &__title {
        margin-bottom: 1.2rem;
        font-size: $font-size-5;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        @include tablet-max {
          font-size: 1.8rem;
        }
      }

      &__sub-title {
        font-size: $font-size-3;
        line-height: normal;
        font-weight: 600;
        margin-left: 0.4rem;

        @include tablet-max {
          font-size: $font-size-2;
          line-height: 2.1rem;
        }
      }

      &__rate {
        margin-bottom: 1.2em;

        &-diff {
          margin-left: 1.4rem;
          margin-bottom: 0;
          padding: 0.4rem 0.8rem;
          border-radius: var(--border-radius-small);
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 2rem;

          @include tablet-max {
            margin-left: 0.9rem;
            margin-bottom: 0;
            padding: 0.4rem 0.6rem;
            font-size: 1.4rem;
            line-height: 1.9rem;
          }

          &--increase {
            background: $light-green;
            color: $green;
          }

          &--decrease {
            background: $light-red;
            color: $red;
          }
        }

        &-large {
          font-weight: 700;
          font-size: $font-size-9;
          line-height: 3.2rem;

          @include tablet-max {
            font-size: 2.8rem;
          }
        }

        &-small {
          font-style: normal;
          font-weight: 600;
          font-size: $font-size-5;
          line-height: 2.6rem;
          display: flex;
          align-items: center;
          margin-bottom: 0;
        }
      }
    }
  }
}
