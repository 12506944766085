@import "src/app/styles/mixins/media-queries";

.description-block {
  margin: 2.5rem 0;
  max-width: 40rem;
  width: 100%;

  @include tablet-max {
    max-width: 100%;
  }

  &__text {
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $black;
  }
}
